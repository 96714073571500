<template>
  <a :href="this.notification.data.sport_competition_url" class="notification-item-wrap notification-item-wrap_plus">
    <div class="event-name">
      <a :href="this.notification.data.profile_url">
        {{ I18n.t("sport_competitions.notifications.sport_competition_suggest_loser_revenge_title") }}
      </a>
    </div>

    <div class="notification-item">
      <div class="notification-item-message">
        <span class="">
          {{ I18n.t("sport_competitions.notifications.sport_competition_suggest_loser_revenge", { "nickname": this.notification.data.profile_nickname } ) }}
        </span>
      </div>
    </div>
  </a>
</template>

<script>
  export default {
    props: {
      notification: Object,
    },
    data() {
      return {
      }
    },
    methods: {
    }
  }
</script>

<style scoped>
</style>
