<template>
  <div class="invite-user">
    <a class="back-link" :href="`/users/${this.inviting_user.nickname}`" style="text-decoration: none;">
      <div class="back-link-wrap" v-if="$mq == 'lg'">
        <font-awesome-icon icon="arrow-left" size="sm" />
        <span style="font-size: 14px; font-weight: 400;">{{ I18n.t("common.buttons.back") }}</span>
      </div>
    </a>

    <div  class="page-title" v-if="$mq != 'sm'" style="margin-top: 24px; margin-bottom: 24px;">
      {{ I18n.t("users.titles.invite", { nickname: this.inviting_user.nickname }) }}
    </div>

    <div class="events-list__items" v-if="sport_events.length > 0">
      <minimal-event-list-item :sport_event="sport_event" :inviting_user="inviting_user" v-for="sport_event in sport_events" />
    </div>

    <div style="padding-top: 48px; text-align: center; font-size: 15px;" v-else>
      <p>{{ I18n.t("sport_events.invite.not_found") }}</p>

      <p>{{ I18n.t("sport_events.invite.not_found_invite_suggestion", { nickname: inviting_user.nickname }) }}</p>

      <a href="/sport_events/new" class="btn cta-btn purple" style="margin-top: 12px;">
        + {{ I18n.t("sport_events.index.new_button")}}
      </a>
    </div>

    <modals-container />
  </div>
</template>

<script>
  import axios from "axios"
  import showAlert from "../../utils/alert"
  import showAvatar from "../../utils/avatar"

  export default {
    props: {
      inviting_user: Object,
      current_user: Object,
      sport_events: Array,
    },
    data() {
      return {
      }
    },
    mounted() {
    },
    methods: {
      logoName(rank) {
        return `${rank}-medal`
      },
      avatarPath(user) {
        return showAvatar(user, 50)
      },
      formattedTime(sportEvent) {
        const minutes = ('0' + sportEvent.start_at_minutes).slice(-2)
        const time = `${sportEvent.start_at_hour}:${minutes}`

        if (this.show_date && !sportEvent.start_date_from_now) {
          return `${this.startDateStr} ${time}`
        } else {
          return time
        }
      },
    },
    computed: {
    }
  }
</script>

<style scoped>
  .invite-user {
    padding: 0px 15px;
    padding-top: 45px;

    @media only screen and (max-width: 600px) {
      padding-bottom: 24px;
    }
  }

  .events-list__items {
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
</style>
