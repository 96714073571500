<template>
  <a href="/users/last_signed_up" :class="notificationLinkClass()">
    <div class="event-name">
      <a href="/users/last_signed_up">
        {{ I18n.t("users.notifications.new_users_created_title") }}
      </a>
    </div>

    <div class="notification-item">
      <div class="notification-item-message" style="line-height: 1.1;">
        <span v-html="renderMessage()"></span>
      </div>
    </div>
  </a>
</template>

<script>
  export default {
    props: {
      notification: Object,
    },
    data() {
      return {
      }
    },
    methods: {
      renderMessage() {
        return I18n.t(
          "users.notifications.new_users_created",
          {
            count: this.notification.data.count_of_new_users
          }
        )
      },
      notificationLinkClass() {
        return "notification-item-wrap notification-item-new-users-created"
      }
    }
  }
</script>

<style scoped>
</style>
