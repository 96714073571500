<template>
  <div class="sport-event-partners">
    <a class="back-link" :href="`/sport_events/${this.sport_event.id}`" style="text-decoration: none;">
      <div class="back-link-wrap" v-if="$mq == 'lg'">
        <font-awesome-icon icon="arrow-left" size="sm" />
        <span style="font-size: 14px; font-weight: 400;">{{ I18n.t("common.buttons.back") }}</span>
      </div>
    </a>

    <div class="participants">
      <div class="participation__requests" v-if="isUserCreator() && requestsFromParticipants.length > 0">
        <div class="participants--title">
          <font-awesome-icon icon="user-clock" />
          {{ I18n.t("sport_events.show.participation_requests", { count: requestsFromParticipants.length }) }}
        </div>

        <div class="participant" v-for="participant in requestsFromParticipants">
          <div class="participant__info">
            <div class="participant__avatar">
              <img :src="avatarPath(participant)" loading="lazy">
            </div>
            <div class="participant__profile-link" >
              <a :href="`/users/${participant.id}`" :class="participantNicknameColorClass(participant)">
                @{{ participant.nickname }}
              </a>
            </div>
            <div :class="logoName(participant.rank)" style="height: 25px; margin-left: 5px"></div>
          </div>

          <div class="participant__actions">
            <div v-if="participantInActionId == participant.id" class="loader">
            </div>
            <button v-on:click.prevent.stop="participationRequest('request_from_participant_accepted', participant)" class="btn btn-success btn-sm participant__actions__accept__button" v-if="participantInActionId != participant.id">
              {{ I18n.t("sport_events.show.participation_accept_button") }}
            </button>
            <button v-on:click.prevent.stop="openRejectFromCreatorModal(participant.id)" class="btn btn-danger btn-sm" v-if="participantInActionId != participant.id && ['waiting', 'ready'].includes(sportEvent.status)">
              {{ I18n.t("sport_events.show.participation_reject_from_creator_button") }}
            </button>
            <span v-if="participantMessage(participant.id) && $mq !== 'sm'" class="request-message" style="margin-left: 12px">
              <a style="cursor: pointer" v-on:click.prevent.stop="openRequestMessageModal(participantMessage(participant.id))">
                <font-awesome-icon icon="comment" size="sm" />
                {{ I18n.t("sport_events.message") }}
              </a>
            </span>

            <span v-if="participantMessage(participant.id) && $mq === 'sm'" class="request-message" style="margin-left: 12px">
              <a style="cursor: pointer" v-on:click.prevent.stop="openRequestMessageModal(participantMessage(participant.id))">
                <font-awesome-icon icon="comment" size="sm" />
              </a>
            </span>
          </div>
        </div>
      </div>

      <div class="participants__accepted">
        <div class="participants--title">
          <font-awesome-icon icon="users" size="lg" />
          {{ I18n.t("sport_events.show.participants", { current_count: acceptedParticipants.length, required_count: sportEvent.minimal_participants_count }) }}
        </div>

        <div class="participant" v-for="participant in acceptedParticipants">
          <div class="participant__info">
            <div class="participant__avatar">
              <img :src="avatarPath(participant)" loading="lazy">
            </div>
            <div class="participant__profile-link">
              <a :href="`/users/${participant.id}`" :class="participantNicknameColorClass(participant)">
                @{{ participant.nickname }}
              </a>
            </div>
            <div :class="logoName(participant.rank)" v-if="(['waiting', 'ready'].includes(sportEvent.status) && user.id == participant.id && !isUserCreator()) || reactedParticipants.includes(participant.id) || canShowParticipantReactions(participant)" style="height: 25px; margin-left: 5px"></div>
          </div>
          <div :class="logoName(participant.rank)" v-if="!(['waiting', 'ready'].includes(sportEvent.status) && user.id == participant.id && !isUserCreator()) && !reactedParticipants.includes(participant.id) && !canShowParticipantReactions(participant)"></div>
        </div>
      </div>

      <div class="participants__requested" v-if="isUserCreator() && requestsFromCreator.length > 0">
        <div class="participants--title">
          <font-awesome-icon icon="user-clock" />
          {{ I18n.t("sport_events.show.participants_requested") }}
        </div>

        <div class="participant" v-for="participant in requestsFromCreator">
          <div class="participant__info">
            <div class="participant__avatar">
              <img :src="avatarPath(participant)" loading="lazy">
            </div>
            <div class="participant__profile-link">
              <a :href="`/users/${participant.id}`" :class="participantNicknameColorClass(participant)">
                @{{ participant.nickname }}
              </a>
            </div>
          </div>

          <div class="participant-response-status">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAEzUlEQVRIie2WXUxURxTHf3PvsouK0IUVVmGrwPJtpbSSlNaPijRqKlZrBE0qJsSYtA/FWH1AJC6NJTGGNvGlaeNLjQWjLzVStaXpB7WxxhqTKoKCYKWu0LK4iisfy73Th7rbhWVhpX3seTr3zH/mN2fuzJmBadi+xhVH9p5amTCdvj5TptNJIlYqivyl6nTRkumCxdOIT7Q4jMODI2XtvZc/1HXvbIHQrc8kf1GYXl6XGf/7RSFKtP8UXH/Fsd7tcR5yDdxNHR4dDOpji82kMGOrSwhxGqHW2WPzr/0rcMMVxwK35943TndHqpRyQs1MYwzL0zeTED3fF9IkHI006LttMS/3PzX4g682LFSEuOwZdhsBFKGSbFkks6xLxQ/tnzMw5CLZkseGRe8RYYjAM9I/6hlxKxKpPBm5Q9flunRLQWvY4H2NRVlI/SfADJBiyZWv57wr4mcvAOCjb7eSm1TEirQyhPhnCK82hGvwjuYZua8+Cd33eB8sy7WuClr6ILDj3KpYr9d7UYAdgSxM2yZWpJeN0fwx8Bvxs+eP7+q3+4NO2T/YjdPdLi52nR62J+ZlbsiqvB2oCTpO3lFvnQA7wGsZ2xkPBYKgHo9nzLd5xjyBVGlq/YwHg30m55+dTePHGAOu/rLoOSHZCpCZUKAttW8Oa9cXFxezf//+MbHUuMUidc7zEqDnYaf9+KWaN0KCdanvAlQFVV+d/bYqwjhtZ8+epbm5mdLS0uAJ5VQIRVGRUtI/5Dw0Idjx3asGISkGyLQW6HGzEqeE6rpOVVUV5eXlZGdnB7XHzkrCbsmTAK5H3fYTLQ5jEFh7pOYDcQDZc5cZpqQCDQ0NtLa2Ul1dHVKTYX1FAAyPDgltmLeCwLqUC3x+Ykz6lNDOzk6qqqrYuXMnNpstpC7ZvMjvD408etHnB2bmX9voSMuk0O7ubtaVLSHSOsqWLVsm1UbPiPf7uq496/P9GQtF+GuiZOLyGGi+wnHw4EFClVMAXQbcG4rQg8BSl06fPzDkmhRqs9k4U/8zbxZup76+nh07doSEux/f8/sGxdjl9wPm1uWbx90HN7FEhf5vPnhtbS1Go5GamhpSUlKorKwM0t1xt/h9oyHi1yDwzaE5lzJmuPoAy/V7zaO5iSvD2tkOhwOz2czGjRsnbG/tOS8BYTLM1FWTeswX9y/1yZKTmhQ0ArT1XFBcnrvhcAGoqKggKSkpKN77sJOuvqsCwBKVeKskxzESBAaQ6HWApqMp565/rIWzyUKZRNLYcljqUkMRAnNkwp7A9jHg2rXfXwOOArT1XlB/7Dg+bXJT26fytuvvbOfGpN3YnP/+qZBgAENExG6gHaDpxhGaO+rl02QukXzd9ok8f+ukAIgymb1x0ZY143VhPQQy4l/S1uS8o05Vv3sfdtLYctifqckQKdMtL6wtzT9wJixwAPwUkAagoOqZ1gI9e+4yw7yYNGIi56BLjf7HTrrdLbT2nJddfVeFr2BEmczeZHPW+omgk4LB9xoZPSSQ2wB1Mq3PFCGwxqTetETHry7JPdAVShfWRb+38dWFilR2AcXAhIXcZJipW6ISb5kjE/aM30jTBvts04lNauasvsW6JlIE0ppiyVuuqsZuY0TEFdWkHgs8p//bePsLfzXJGhkK9T8AAAAASUVORK5CYII="/>
          </div>
        </div>
      </div>
    </div>

    <modals-container />
  </div>
</template>

<script>
  import axios from "axios"
  import showAlert from "../../utils/alert"
  import showAvatar from "../../utils/avatar"
  import SportEventDislikeForm from "../../components/sport_events/modals/dislike_report.vue"
  import SportEventReviewForm from "../../components/sport_events/modals/review.vue"
  import SportEventRejectFromCreator from "../../components/sport_events/modals/reject_participant_from_creator.vue"
  import SportEventRequestMessage from "../../components/sport_events/modals/request_message.vue"

  export default {
    props: {
      creator: Object,
      user: Object,
      sport_event: Object,
    },
    data() {
      return {
        participantInActionId: null,
        reactedParticipants: [],
        currentProcessedReactions: {},
        sportEvent: this.sport_event,
      }
    },
    created() {
      this.$eventHub.$on('change-sport-event', this.resetSportEvent);
    },
    mounted() {
    },
    methods: {
      creatorProfileLink() {
        return `/users/${this.creator.id}`
      },
      avatarPath(user) {
        return showAvatar(user, 50)
      },
      resetSportEvent(emmittedSportEvent) {
        this.sportEvent = emmittedSportEvent
      },
      isUserCreator() {
        return this.creator.id == this.user.id
      },
      canShowParticipantReactions(participant) {
        return this.sportEvent.status == 'finished' &&
               this.participantIds.includes(this.user.id) &&
               this.user.id != participant.id &&
               !_.map(this.sportEvent.reactions, 'for_user_id').includes(participant.id)
      },
      skipSubscribersHighlight(val) {
        this.sportEvent.share_link_highlight = false
      },
      resetSportEvent(emmittedSportEvent) {
        this.sportEvent = emmittedSportEvent
      },
      participationRequest(actionType = "", participant = null) {
        const requestParams = { sport_event_id: this.sportEvent.id }

        // TODO: rewrite this function plz
        if (participant) {
          requestParams["user_id"] = participant.id
          this.participantInActionId = participant.id
        }

        const action = !!actionType ? "accept" : ""

        let successNotificationKey = null

        if (action == "accept") {
          requestParams["action_type"] = actionType
          successNotificationKey = actionType
        } else {
          successNotificationKey = "participation_request_created"
        }

        // TODO: use error messages from response
        axios
          .post(
            `/api/v1/sport_event_users/participations/${action}`,
            { sport_event_user: requestParams }
          )
          .then(response => {
            showAlert(I18n.t(`sport_events.show.notifications.success.${successNotificationKey}`))

            this.sportEvent = response.data["sport_event"]
          })
          .catch(error => {
            showAlert(
              I18n.t("sport_events.show.notifications.failed_participation_request"),
              "alert"
            )
          })
          .then(() => {
            this.participantInActionId = null
            requestWebpushSubscription(this.user)
          })
      },
      participationReject(requestConfirmation = true) {
        let confirmed = true

        if (requestConfirmation) {
          const message = I18n.t("sport_events.show.reject_participation_confirm_message")
          let confirmed = confirm(message)
        }

        if (confirmed) {
          axios
            .post(
              "/api/v1/sport_event_users/participations/reject",
              { sport_event_user: { sport_event_id: this.sportEvent.id } }
            )
            .then(response => {
              showAlert(I18n.t("sport_events.show.notifications.success.participation_rejected"))

              this.sportEvent = response.data["sport_event"]
            })
            .catch(error => {
              showAlert(
                I18n.t("sport_events.show.notifications.failed_participation_reject"),
                "alert"
              )
            })
            .then(() => {
              this.participantInActionId = null
            })
        }
      },
      participantMessage(participantId) {
        return this.sportEvent.participation_requests_messages[participantId]
      },
      logoName(rank) {
        return `${rank}-medal`
      },
      fullName(user) {
        if (!user.name && !user.surname) {
          return
        }

        return `${this.user.name ? this.user.name : ''} ${this.user.surname ? this.user.surname : ''}`;
      },
      copyEvent() {
        Turbolinks.visit(`/sport_events/new?sport_event_id=${this.sportEvent.id}`)
      },
      participantNicknameColorClass(user) {
        if (user.id == this.creator.id) {
          return "gold-nickname"
        }
      },
      openParticipationWithCommentModal(participantId) {
        this.$modal.show(SportEventRequestWithComment,
          {
            sportEventId: this.sportEvent.id,
            participantId: participantId
          },
          {
            pivotX: (this.$mq === "sm" ? 0 : 0.5),
            width: (this.$mq === "sm" ? "100%" : 600)
          }
        )
      },
      openDislikeReportModal(reportedUserId) {
        this.$modal.show(SportEventDislikeForm,
          {
            sportEventId: this.sportEvent.id,
            reportedUserId: reportedUserId,
          },
          {
            pivotX: (this.$mq === "sm" ? 0 : 0.5),
            width: (this.$mq === "sm" ? "100%" : 600)
          }
        )
      },
      openReviewModal() {
        this.$modal.show(SportEventReviewForm,
          {
            sportEventId: this.sportEvent.id,
            isCreator: this.isUserCreator()
          },
          {
            pivotX: (this.$mq === "sm" ? 0 : 0.5),
            width: (this.$mq === "sm" ? "100%" : 600)
          }
        )
      },
      openRequestMessageModal(text) {
        this.$modal.show(SportEventRequestMessage,
          {
            text: text
          },
          {
            pivotX: (this.$mq === "sm" ? 0 : 0.5),
            width: (this.$mq === "sm" ? "100%" : 600)
          }
        )
      },
      openRejectFromCreatorModal(participantId) {
        this.$modal.show(SportEventRejectFromCreator,
          {
            participantId: participantId,
            sportEventId: this.sportEvent.id
          },
          {
            pivotX: (this.$mq === "sm" ? 0 : 0.5),
            width: (this.$mq === "sm" ? "100%" : 600)
          }
        )
      },
    },
    computed: {
      requestsFromParticipants() {
        return this.sportEvent.participation_requests
      },
      requestsFromCreator() {
        return this.sportEvent.participants_requests
      },
      acceptedParticipants() {
        return this.sportEvent.accepted_participants
      },
      participantIds() {
        return _.map(this.acceptedParticipants, "id")
      },
    }
  }
</script>

<style scoped>
  .sport-event-partners {
    padding: 0px 15px;
    padding-top: 65px;

    @media only screen and (max-width: 600px) {
      padding-bottom: 24px;
    }
  }

  .participants {
    text-align: center;
    margin-top: 15px;
  }

  .participants__accepted {
    margin: 50px 0px;
  }

  .participation__requests {
    margin: 40px 0px;
  }

  .participant {
    display: flex;
    margin: 10px 0px;
    justify-content: space-between;
    padding: 5px 0px;

    @media only screen and (min-width: 600px) {
      border-bottom: 1px solid #dcdbdb;
    }
  }

  .participant__avatar img {
    border-radius: 30px;
    width: 30px;
    height: 30px;
  }

  .participant__info {
    display: flex;
    align-items: center;
  }

  .participant__actions button {
    font-size: 13px !important;

    @media only screen and (max-width: 600px) {
      font-size: 12px !important;
    }
  }

  .participant__actions .reactions {
    display: flex;
  }

  .participant__actions .reactions .like {
    margin-right: 10px;
  }

  .participant__actions .reactions .dislike svg {
    width: 20px;
    height: 20px;
  }

  .participant__actions__accept__button {
    @media only screen and (max-width: 600px) {
      margin-right: 5px;
    }
  }

  .participants--title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 24px;
  }

  .participant__actions svg {
    height: 15px;
    width: 15px;
  }

  .participant__actions__accept {
    color: green !important;
  }

  .participant__profile-link {
    margin-left: 5px;
  }

  .participant a {
    text-decoration: none;
  }

  .loader {
    background: url("../../images/loader.gif");
    background-repeat: no-repeat;
    height: 15px;
    width: 15px;
  }

  .request-from-creator-info {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .reaction-success-message {
    font-size: 13px;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 600px) {
      font-size: 12px !important;
    }
  }

  .reaction-success-message svg {
    margin-right: 3px;
    color: green;
  }

  .beginner-medal {
  }

  .bronze-medal {
    background: url("../../images/bronze_medal.svg");
    background-repeat: no-repeat;
    width: 38px;
    height: 35px;
  }

  .silver-medal {
    background: url("../../images/silver_medal.svg");
    background-repeat: no-repeat;
    width: 38px;
    height: 35px;
  }

  .gold-medal {
    background: url("../../images/gold_medal.svg");
    background-repeat: no-repeat;
    width: 38px;
    height: 35px;
  }
</style>
