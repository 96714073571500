<template>
  <div class="event-dislike-report">
    <div class="title">{{ I18n.t("sport_events.dislike_report.title") }}</div>

    <div v-if="responseCompleted" class="completed-info-wrap">
      <div class="completed-info">
        <div class="completed-info-img">
          <img src="../../../images/success.png" loading="lazy">
        </div>
        {{ I18n.t("sport_events.dislike_report.success_completed") }}
      </div>

      <div class="submit-button-wrap">
        <a href="" class="btn cta-btn cancel" v-on:click.prevent.stop="$emit('close')">
          {{ I18n.t("common.buttons.close") }}
        </a>
      </div>
    </div>

    <div v-else>
      <div class="inputs-wrap">
        <div v-for="purpose in this.purposes">
          <input :id="purpose" type="radio" :value="purpose" v-model="selectedPurpose">
          <label :for="purpose">
            {{ I18n.t(`sport_events.dislike_report.${purpose}`) }}
          </label>
        </div>
      </div>
      <div class="submit-button-wrap">
        <a href="" class="btn cta-btn btn-sm purple" v-on:click.prevent.stop="sendResponse()">
          {{ I18n.t("common.buttons.save") }}
        </a>

        <a href="" class="btn cta-btn btn-sm cancel" v-on:click.prevent.stop="$emit('close')">
          {{ I18n.t("common.buttons.cancel") }}
        </a>
      </div>
    </div>

  </div>
</template>

<script>
  import axios from "axios"

  export default {
    props: {
      sportEventId: Number,
      reportedUserId: Number,
    },
    data: function() {
      return {
        selectedPurpose: null,
        responseCompleted: false,
        purposes: ["did_not_come", "level_is_not_confirmed", "other"]
      }
    },
    computed: {
    },
    methods: {
      sendResponse() {
        axios
            .post(`/api/v1/user_sport_event_reactions/`,
              {
                user_sport_event_reaction: {
                  for_user_id: this.reportedUserId,
                  sport_event_id: this.sportEventId,
                  reaction: this.selectedPurpose
                }
              }
            )
            .then(response => {
              console.log("SPORT EVENT", response.data["sport_event"])
              this.responseCompleted = true
              this.$eventHub.$emit('change-sport-event', response.data["sport_event"])
            })
            .catch(error => {
              console.log(error)
            })
      }
    }
  }
</script>

<style scoped>
  .event-dislike-report {
    height: 100%;
  }

  .completed-info-wrap {
    height: 80%;
    padding-top: 20px;
    font-size: 18px;

    @media only screen and (max-width: 600px) {
      font-size: 16px !important;
    }
  }

  .completed-info {
    height: 70%;
    text-align: center;
    font-size: 14px;
  }

  .completed-info-img img {
    width: 120px;
    height: 120px;
  }

  .event-dislike-report input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  .event-dislike-report .inputs-wrap {
    text-align: center;
    margin-bottom: 20px;
  }

  .event-dislike-report label {
    display: inline-block;
    background-color: #efeff6;
    padding: 12px 18px;
    font-size: 14px;
    border: 1px solid #c3c3c3;
    color: #232946;
    font-weight: 500;
    border-radius: 5px;
    width: 80%;
  }

  .event-dislike-report input[type="radio"]:checked + label {
    background-color: #8e8ac1;
    color: #fff;
    border-color: #FFF;
  }

  .event-dislike-report label:hover {
    background-color: #cecee3;
  }

  .event-dislike-report .title {
    box-sizing: border-box;
    padding: 0px 30px;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    font-size: 20px;
    padding-top: 15px;
    margin-bottom: 24px;
  }

  .cta-btn {
    display: inline-block;
    padding: 4px 15px;
    line-height: 30px;
    text-decoration: none;
  }

  .cta-btn.green {
    background-color: #0c6035;
    color: #fff;
  }

  .cta-btn.cancel {
    color: grey;
    font-size: 13px;
  }

  .submit-button-wrap {
    text-align: center;

    @media only screen and (max-width: 600px) {
      margin-top: 12px;
      margin-bottom: 20px;
    }
  }
</style>
