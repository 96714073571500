<template>
  <div style="margin-top: 18px; font-size: 12px; color: grey;">
    <div>
      {{ I18n.t('sport_competitions.common_score.score') }}

      <b style="color: black;">{{ result['current_user_wins_count'] }}</b> :
      <b  style="color: black;">{{ result['opponent_wins_count']  }}</b>

      <span v-if="result['current_user_wins_count'] > result['opponent_wins_count']">
        {{ I18n.t('sport_competitions.common_score.your_advance') }}
      </span>

      <span v-if="result['current_user_wins_count'] < result['opponent_wins_count']">
        {{ I18n.t('sport_competitions.common_score.opponent_advance') }}
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      result: Object,
      currentUserId: Number,
      opponentNickname: String
    },
    methods: {

    }
  }
</script>
