<template>
  <div class="sports-list">
    <top-tabs :tabs="this.tabs" v-if="!registration"/>
    <div class="alert alert-warning alert-dismissible sport-notification-info" style="display: flex;" v-if="!registration && (Object.keys(user_sports).length == 0)">
      <button type="button" class="close" data-dismiss="alert">&times;</button>
      <font-awesome-icon icon="lightbulb" size="lg" />
      <div v-html="infoHtml()" style="margin-left: 5px;"></div>
    </div>

    <div class="sport-list">
      <div class="row">
        <sport-item v-for="sport in sports" :sport="sport" :sport_schedules="filterSportSchedules(sport)" :pickedCategory="pickedCategory(sport)" v-bind:key="sport.id"/>
      </div>
    </div>

    <nav class="navbar fixed-bottom navbar-light bg-light save-button-nav" v-if="!registration">
      <button class="btn btn-primary save-button" type="submit">{{I18n.t('forms.submit')}}</button>
    </nav>

    <nav class="navbar fixed-bottom navbar-light bg-light save-button-nav registration-buttons-wrap" v-if="registration">
      <div class="registration-buttons">
      <button class="btn cta-btn grey skip-button" v-on:click.prevent="updateStep(3)">
        {{ I18n.t("registration.first_step.buttons.skip_step") }}
        </button>
        <button class="btn cta-btn purple next-button" style="width: 48%;" type="submit" >
          <font-awesome-icon icon="arrow-circle-right" size="1x" />
          {{ I18n.t("registration.second_step.buttons.next") }}
        </button>
      </div>
    </nav>
  </div>
</template>

<script>
  import _ from "lodash"
  import showAlert from "../../utils/alert"
  import updateStepMixin from "../mixins/update_step"

  export default {
    props: {
      sports: Array,
      sport_schedules: Array,
      user_sports: Object,
      registration: { type: Boolean, default: false },
    },
    mixins: [updateStepMixin],
    data() {
      return {
        tabs: [
          { code: "edit_profile", href: "/users/edit_profile" },
          { code: "sport_settings", href: "/user_settings/sports", selected: true },
        ]
      }
    },
    methods: {
      pickedCategory: function(sport) {
        return this.user_sports[sport.code] || ""
      },
      filterSportSchedules: function(sport) {
        return _.filter(this.sport_schedules, { sport_id: sport.id })
      },
      userEditLink() {
        return `/users/edit_profile`
      },
      infoHtml() {
        return I18n.t("sports_form.info.first_paragraph")
      }
    },
    mounted() {
      const localRegistration = this.registration
      const localupdateStepFunc = this.updateStep
      const context = this

      $("#edit_player").on("ajax:success", function(event, xhr, status, error) {
        if (localRegistration) {
          localupdateStepFunc.call(context, 3)
        } else {
          showAlert(I18n.t("users.edit_form.success_notification"))
        }
      })
    }
  }
</script>

<style scoped>
  .sports-list {
    padding-top: 65px;
    padding-bottom: 62px;
    width: 100%;

    font-size: 14px !important;

    @media only screen and (max-width: 600px) {
      font-size: 13px !important;
      padding-bottom: 24px;
    }
  }

  .sport-notification-info {
    @media only screen and (max-width: 600px) {
      line-height: 1.3;
    }
  }

  .sport-list {
    display: flex;

    @media only screen and (max-width: 600px) {
      padding-bottom: 60px;
    }
  }

  .controls {
    margin-top: 20px;
    text-align: right;
  }

  .registration-buttons-wrap {
    @media only screen and (max-width: 600px) {
      justify-content: space-around;
    }
  }

  .registration-buttons {
    max-width: 800px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    @media only screen and (min-width: 600px) {
      text-align: center;
    }
  }

  .registration-buttons .skip-button {
    width: 47%;
    margin-right: 12px;

    @media only screen and (min-width: 600px) {
      width: 40%;
    }
  }

  .registration-buttons .next-button {
    width: 50%;

    @media only screen and (min-width: 600px) {
      width: 40%;
    }
  }
</style>
