<template>
  <div class="users">
    <div style="margin-bottom: 36px; display: flex; align-items: center; justify-content: space-between;" v-if="$mq == 'lg'">
      <div>
        <a class="back-link" href="/" style="text-decoration: none;">
          <div class="back-link-wrap" >
            <font-awesome-icon icon="arrow-left" size="sm" />
            <span style="font-size: 14px; font-weight: 400;">{{ I18n.t("common.buttons.back") }}</span>
          </div>
        </a>
      </div>
      <div>
        <label class="input input--actor" style="display: flex; align-items: center;" ref="label">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-search"><circle cx="11" cy="11" r="8"/><path d="m21 21-4.3-4.3"/></svg>
          <input v-model="usersSearch" name="users_search" id="search_input" type="search" autocomplete="off" v-on:keyup.enter="searchUsers()"/>
          <div style="width: 20px; cursor: pointer;" @click="clearSearch">
            <svg v-if="!!usersSearch" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
          </div>
        </label>
      </div>
    </div>

    <div v-if="$mq !== 'lg'">
      <label class="input input--actor" style="display: flex; align-items: center;" ref="label">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-search"><circle cx="11" cy="11" r="8"/><path d="m21 21-4.3-4.3"/></svg>
        <input v-model="usersSearch" name="users_search" id="search_input" autocomplete="off" type="search" v-on:keyup.enter="searchUsers()"/>
        <div style="width: 20px;" @click="clearSearch">
          <svg v-if="!!usersSearch" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
        </div>
      </label>
    </div>

    <div class="page-title" style="line-height: 1.2;">
      {{ titleText() }}
    </div>

    <div v-if="this.selected_sport" style="margin-bottom: 24px; padding-bottom: 12px; border-bottom: 1px solid #e0dbdb;">
      <div style="margin-top: -18px; display: flex; align-items: center; font-size: 14px;">
        {{ I18n.t("users.filter.title") }}
        <div class="user-sport-item--small">
          <div class="sport-item__image--small" :class="this.selected_sport"></div>
        </div>
      </div>
      <div style="margin-bottom: 12px;">
        <a :href="clearSportLink()" class="light-link" style="font-size: 13px;">{{ I18n.t("users.filter.clear") }}</a>
      </div>
    </div>

    <div class="users-list" v-if="usersLocal.length > 0 && !loadingUsers">
      <div class="users-item" :class="selectedCssCompetitionUserId(user.id)" v-for="user in this.usersLocal">
        <div class="item-first-row">
          <div class="user-avatar-nickname-wrap">
            <user-list-avatar :user="user" />
            <div class="user-sports">
              <div class="user-sports-links" v-for="(level, sport, index) in user.sports">
                <a :href="sportLink(sport)">
                  <div class="user-sport-item">
                    <div class="sport-item__image" :class="sport"></div>
                    <div class="sport-item__level"> {{ level }}</div>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div v-if="competitions_invite_mode && user.id != current_user_id" class="competition-input-wrap">
            <div :class="logoName(user.rank)" class="medal"></div>
            <div class="competition-input">
              <input v-model="userSelectedForCompetition" :value="user" :name="`selected_user_${user.id}`" type="radio"/>
            </div>
          </div>

          <div :class="logoName(user.rank)" class="medal" v-else></div>
        </div>

        <div class="item-second-row">
          <div>
            <div v-if="user.success_taken_part_events_count > 0" class="successful-events">
              {{ I18n.t("users.profile.user_event_link", { count: user.success_taken_part_events_count } ) }}
            </div>
            <div class="current-geo-position">
              <font-awesome-icon icon="map-marker-alt" size="1x" />
              {{ `${user.current_geo}` }}
            </div>
          </div>
          <div style="margin-left: 6px; margin-top: 12px;">
            <div >
              <div class="badge badge-yellow" v-if="user.finished_competitions_count > 1">
                {{ I18n.t('users.badges.like_to_compete') }}
              </div>

              <div class="badge badge-yellow" v-if="user.play_activity_weeks_streak > 1">
                {{ I18n.t('users.badges.constantly_training') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="usersLocal.length == 0">
      <div style="margin-top: 50px; text-align: center; margin-bottom: 16px;">
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-off"><path d="m2 2 20 20"/><path d="M8.35 2.69A10 10 0 0 1 21.3 15.65"/><path d="M19.08 19.08A10 10 0 1 1 4.92 4.92"/></svg>
      </div>
      <div style="text-align: center;">
        {{ I18n.t('users.index.nobody_found', { search: this.usersSearch }) }}
      </div>
    </div>

    <div v-else>
      <img src="../../images/loading.gif" style="width: 80px;">
    </div>

    <nav class="navbar fixed-bottom navbar-light bg-light request-competition-link-wrap" v-if="competitions_invite_mode && userSelectedForCompetition">
      <a :href="`/sport_competitions/new?user_id=${userSelectedForCompetition.id}`" class="btn cta-btn purple btn-lg" style="max-width: 100%;">
        {{ I18n.t('users.index.request_competition_link', { nickname: this.userSelectedForCompetition.nickname }) }}
        <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L6 5.73684L1 10" stroke="white"/>
        </svg>
      </a>
    </nav>

    <modals-container />

    <scroll-loader :loader-method="getUsersList" :loader-disable="disable">
    </scroll-loader>
  </div>
</template>

<script>
  import axios from "axios"

  export default {
    props: {
      users: Array,
      users_count: Number,
      sports: Array,
      cities: Array,
      selected_sport: String,
      competitions_invite_mode: Boolean,
      current_user_id: Number
    },
    data: function() {
      return {
        disable: this.users.length < 20 ? true : false,
        page: 2,
        pageSize: 20,
        usersLocal: this.users,
        userSelectedForCompetition: null,
        usersSearch: '',
        loadingUsers: false
      }
    },
    methods: {
      logoName(rank) {
        return `${rank}-medal`
      },
      showName(user) {
        if (user.nickname.includes("spotty_") && this.fullName(user))
          return this.fullName(user)

        return `@${user.nickname}`
      },
      fullName(user) {
        if (user.name || user.surname)
          return `${user.name ? user.name : ''} ${user.surname ? user.surname : ''}`;
      },
      selectedCssCompetitionUserId(userId) {
        if (!this.competitions_invite_mode) {
          return ""
        }

        if (this.userSelectedForCompetition && userId == this.userSelectedForCompetition.id)
          return "selected-user-wrap"

        return ""
      },
      sportLink(sport) {
        if (this.competitions_invite_mode)
          return `/users?sport=${sport}&competition_invite=1`

        return `/users?sport=${sport}`
      },
      clearSportLink() {
        if (this.competitions_invite_mode)
          return `/users?competition_invite=1`

        return `/users`
      },
      titleText() {
        if (this.competitions_invite_mode) {
          if (this.$mq !== 'lg') {
            return I18n.t("users.index.request_user_to_competition_sm")
          } else {
            return I18n.t("users.index.request_user_to_competition")
          }
        }

        return I18n.t("users.index.title", { users_count: this.users_count })
      },
      clearSearch() {
        this.usersSearch = ''

        document.getElementById('search_input').blur()

        this.searchUsers()
      },
      searchUsers() {
        this.loadingUsers = true
        this.userSelectedForCompetition = null

        axios
          .get(
            "/api/v1/users",
            {
              params: {
                sport: this.selected_sport,
                q: this.usersSearch,
              }
            }
          )
          .then(response => {
            this.usersLocal = response.data
          })
          .catch(error => {
            console.log("ERROR: ", error)
          })
          .finally(() => (this.loadingUsers = false));
      },
      getUsersList() {
        if(this.usersLocal.length < 20)
          return

        axios
          .get(
            "/api/v1/users",
            {
              params: {
                sport: this.selected_sport,
                page: this.page++,
                page_size: this.pageSize,
              }
            }
          )
          .then(response => {
            this.usersLocal = [...this.usersLocal, ...response.data]

            this.disable = response.data.length < this.pageSize
          })
          .catch(error => {
            console.log("ERROR: ", error)
          })
      }
    }
  }
</script>

<style scoped>
  .users {
    padding: 0px 15px;
    padding-top: 65px;

    @media only screen and (max-width: 600px) {
      padding-bottom: 60px;
    }
  }

  .page-title {
    margin-bottom: 24px;

    @media only screen and (max-width: 600px) {
      margin-bottom: 12px;
    }
  }

  .users-list {
    font-size: 14px;

    padding-bottom: 20px;

    @media only screen and (max-width: 600px) {
      font-size: 13px;
    }
  }

  #users_search {
    background: white;
  }

  .users-item {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    border-bottom: 1px solid #e0dbdb;
    padding-bottom: 15px;
    flex-direction: column;
    align-items: flex-start;
  }

  .item-first-row {
    display: flex;
    margin-bottom: 10px;
    margin-right: 20px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .competition-input-wrap {
    display: flex;
    align-items: center;
  }

  .current-geo-position {
    color: #635f5f;
    margin-left: 6px;
    font-size: 11px;
  }

  .user-avatar-nickname-wrap {
    display: flex;
    align-items: center;
    margin-left: 6px;
  }

  .user-nickname {
    margin-top: 6px;
    margin-left: 6px;
  }

  .user-nickname a {
    text-decoration: none;
  }

  .user-sports {
    display: flex;
    margin-left: 30px;
    margin-top: 10px;
    flex-wrap: wrap;
    flex-basis: 100%;

    @media only screen and (max-width: 600px) {
      margin-left: 0px;
    }
  }

  .request-competition-link-wrap {
  }

  .request-competition-link-wrap a {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: 40px;
    font-size: 1.1rem;

    @media only screen and (max-width: 600px) {
      font-size: 1rem;
      bottom: 35px;
    }
  }

  .user-sports-links {
    margin-bottom: 6px;
  }

  .user-sport-item {
    margin-right: 24px;
    position: relative;
    background-color: #7d79b9;
    height: 40px;
    width: 40px;
    border-radius: 10px;

    @media only screen and (max-width: 600px) {
      height: 35px;
      width: 35px;
      margin-right: 18px;
    }
  }

  .selected-user-wrap {
    border-bottom: 1px solid #7d79b9;
  }

  .user-sport-item--small {
    position: relative;
    background-color: #7d79b9;
    border-radius: 10px;
    height: 35px;
    width: 35px;
    margin-right: 18px;
    margin-left: 12px;

    @media only screen and (max-width: 600px) {
      margin-left: 6px;
    }
  }

  .sport-item__image {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 10px;
    left: 10px;

    @media only screen and (max-width: 600px) {
      height: 20px;
      width: 20px;
      top: 8px;
      left: 8px;
    }
  }

  .sport-item__image--small {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 8px;
    left: 8px;
  }

  .successful-events {
    margin-left: 6px;
    margin-bottom: 8px;
    font-size: 12px;
    color: #635f5f;
  }

  .competition-input-wrap input {
    height: 22px;
    width: 22px;
  }

  .competition-input {
    margin-left: 24px;

    @media only screen and (max-width: 600px) {
      margin-left: 12px;
    }
  }

  .sport-item__level {
    position: absolute;
    bottom: -4px;
    right: -5px;
    font-size: 11px;
    color: black;
    background-color: #F9DB85;
    font-weight: bold;
    border-radius: 20px;
    height: 15px;
    width: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .badminton {
    background-image: url("../../images/mini-image-badminton.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .squash {
    background-image: url("../../images/mini-image-squash.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .football {
    background: url("../../images/mini-image-football.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .basketball {
    background: url("../../images/mini-image-basketball.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .tennis {
    background: url("../../images/mini-image-tennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .tabletennis {
    background: url("../../images/mini-image-tabletennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .volleyball {
    background: url("../../images/mini-image-volleyball.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .medal {
    margin-top: 5px;
  }

  .beginner-medal {
  }

  .bronze-medal {
    background: url("../../images/bronze_medal.svg");
    background-repeat: no-repeat;
    width: 20px;
    height: 25px;
  }

  .silver-medal {
    background: url("../../images/silver_medal.svg");
    background-repeat: no-repeat;
    width: 20px;
    height: 25px;
  }

  .gold-medal {
    background: url("../../images/gold_medal.svg");
    background-repeat: no-repeat;
    width: 30px;
    height: 35px;
  }

  .input {
    appearance: none;
    background-color: white;
    border-radius: 5px;
    font-size: 16px;
    inline-size: 100%;
    min-block-size: 1.5rem;
    padding: 5px;
    border: 1px solid #e4e4e7;
  }

  .input-focused {
    border: 1px solid #e4e4e7;
  }

  .input svg {
    color: grey;
    margin-right: 6px;
    margin-left: 3px;
  }

  .input--actor input {
    border: 0;
    inline-size: 100%;
    outline: 0;
    padding: 0px;
    background: white;
  }
</style>
