<template>
  <div class="sport-competitions">
    <a class="back-link" :href="back_url" style="text-decoration: none;">
      <div class="back-link-wrap" v-if="$mq == 'lg'">
        <font-awesome-icon icon="arrow-left" size="sm" />
        <span style="font-size: 14px; font-weight: 400;">{{ I18n.t("common.buttons.back") }}</span>
      </div>
    </a>

    <div class="opponents" v-if="year_opponent_results.length > 0">
      <div class="page-title">
        {{ I18n.t('sport_competitions.index.titles.this_year_opponents') }}
      </div>

      <div v-for="result in year_opponent_results">
        <div style="display: flex; align-items: center;">
          <div style="display: flex; align-items: center;">
            <a :href="`/users/${result.user.nickname}`" style="margin-right: 32px; text-align: center; font-size: 12px; text-decoration: none;">
              <img :src="imgPath(result.user)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
              <div style="margin-top: 3px;">
                @{{ result.user.nickname }}
              </div>
            </a>
            <div style="font-size: 30px">
              {{ result.user_wins_count }}
            </div>
          </div>
          <div style="display: flex; align-items: center; margin-right: 6px; margin-left: 6px;">:</div>
          <div style="display: flex; align-items: center; margin-right: 24px;">
            <div style="font-size: 30px">
              {{ result.opponent_wins_count }}
            </div>
            <a :href="`/users/${result.opponent.nickname}`" style="margin-left: 32px;  text-align: center; font-size: 12px; text-decoration: none;">
              <img :src="imgPath(result.opponent)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
              <div style="margin-top: 3px;">
                @{{ result.opponent.nickname }}
              </div>
            </a>
          </div>
        </div>
        <div style="margin-top: 12px; font-size: 13px; color: grey;">
          {{ I18n.t('sport_competitions.index.opponent_explainer') }}
        </div>
      </div>
    </div>

    <div class="competitions" style="margin-top: 24px;" v-if="inProgressCompetitions.length > 0">
      <div class="page-title">
        {{ I18n.t('sport_competitions.index.titles.actual_competitions') }}
      </div>

      <div class="in-progress-competitions" v-if="inProgressCompetitions.length > 0">
        <div v-for="competition in inProgressCompetitions"  style="margin-bottom: 24px; padding-bottom: 24px; border-bottom: 1px solid rgb(219, 219, 219);">
          <div style="display: flex; align-items: center;">
            <div style="display: flex; align-items: center;">
              <a :href="`/users/${competition.requested_user.nickname}`" style="margin-right: 24px; text-align: center;">
                <img :src="imgPath(competition.requested_user)" loading="lazy" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
                <div style="margin-top: 3px;">
                  @{{ competition.requested_user.nickname }}
                </div>
              </a>
              <div style="font-size: 30px">
                {{ competition.requested_user_wins }}
              </div>
            </div>
            <div style="display: flex; align-items: center; margin-right: 6px; margin-left: 6px;">:</div>
            <div style="display: flex; align-items: center; margin-right: 24px;">
              <div style="font-size: 30px">
                {{ competition.initiated_user_wins }}
              </div>
              <a :href="`/users/${competition.initiator.nickname}`" style="margin-left: 24px; text-align: center;">
                <img :src="imgPath(competition.initiator)" loading="lazy" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
                <div style="margin-top: 3px;">
                  @{{ competition.initiator.nickname }}
                </div>
              </a>
            </div>

            <div style="padding-left: 24px; border-left: 1px solid #dcdcdc;">
              <div class="sport-alert-item-wrap" style="margin-right: 24px; width: 40px;">
                <div :class="`sport-alert-item mini-${competition.sport_code}`" style="width: 20px; height: 20px;"></div>
              </div>

              <div style="font-size: 13px; color: grey; margin-top: 6px;">до {{ competition.count_of_wins }} побед</div>
            </div>
          </div>

          <div style="margin: 12px 0px;">
            <a :href="`/sport_competitions/${competition.id}`" class="light-link">
              {{ I18n.t('sport_competitions.index.more_info_link') }}
            </a>
          </div>

          <div v-if="competition.unresolved_results.length > 0">
            <div v-for="result in competition.unresolved_results">
              <sport-competition-result-submission :key="result.id" :currentUserId="current_user.id" :result="result" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="competitions" style="margin-top: 24px;" v-if="finishedCompetitions.length > 0">
      <div class="page-title" style="font-weight: 450; color: #626262;">
        {{ I18n.t('sport_competitions.index.titles.finished_competitions') }}
      </div>

      <div class="in-progress-competitions" v-if="finishedCompetitions.length > 0">
        <div v-for="competition in finishedCompetitions" style="margin-bottom: 24px; padding-bottom: 24px; border-bottom: 1px solid rgb(219, 219, 219);">
          <div style="display: flex; align-items: center;">
            <div style="display: flex; align-items: center;">
              <a :href="`/users/${competition.requested_user.nickname}`" style="margin-right: 24px; text-align: center;">
                <img :src="imgPath(competition.requested_user)" loading="lazy" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
                <div style="margin-top: 3px;" :class="finishedNicknameCss(competition.requested_user.id, competition.winner_id)">
                  @{{ competition.requested_user.nickname }}
                </div>
              </a>
              <div style="font-size: 30px">
                {{ competition.requested_user_wins }}
              </div>
            </div>
            <div style="display: flex; align-items: center; margin-right: 6px; margin-left: 6px;">:</div>
            <div style="display: flex; align-items: center; margin-right: 24px;">
              <div style="font-size: 30px">
                {{ competition.initiated_user_wins }}
              </div>
              <a :href="`/users/${competition.initiator.nickname}`" style="margin-left: 24px; text-align: center;">
                <img :src="imgPath(competition.initiator)" loading="lazy" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
                <div style="margin-top: 3px;" :class="finishedNicknameCss(competition.initiator.id, competition.winner_id)">
                  @{{ competition.initiator.nickname }}
                </div>
              </a>
            </div>

            <div style="padding-left: 24px; border-left: 1px solid #dcdcdc;">
              <div class="sport-alert-item-wrap" style="margin-right: 24px; width: 40px;">
                <div :class="`sport-alert-item mini-${competition.sport_code}`" style="width: 20px; height: 20px;"></div>
              </div>

              <div style="font-size: 13px; color: grey; margin-top: 6px;">
                {{ I18n.t('sport_competitions.index.till_wins', { wins_count: competition.count_of_wins }) }}
              </div>
            </div>
          </div>

          <div style="margin: 12px 0px;">
            <a :href="`/sport_competitions/${competition.id}`" class="light-link">
              {{ I18n.t('sport_competitions.index.more_info_link') }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="requests_by_user.length == 0 && inProgressCompetitions.length == 0 && finishedCompetitions.length == 0 && finishedCompetitions.length == 0" style="margin-top: 24px; text-align: center; font-size: 14px;">
      {{ I18n.t('sport_competitions.index.no_competitions_yet') }}
    </div>
    <modals-container />
  </div>
</template>

<script>
  import axios from "axios"
  import showAlert from "../../utils/alert"
  import showAvatar from "../../utils/avatar"

  import DeclineSportCompetition from "../../components/sport_competitions/modals/decline_competition.vue"

  export default {
    props: {
      current_user: Object,
      requests_for_user: Array,
      requests_by_user: Array,
      in_progress_competitions: Array,
      finished_competitions: Array,
      year_opponent_results: Array,
      back_url: String
    },
    data() {
      return {
        successFullRequests: {},
        declinedRequests: {},
        finishedCompetitions: this.finished_competitions,
        inProgressCompetitions: this.in_progress_competitions
      }
    },
    created() {
      this.$eventHub.$on("change-sport-competitions", this.resetSportCompetitions)
      this.$eventHub.$on("change-declined-sport-competitions", this.resetDeclinedRequests)

    },
    methods: {
      imgPath(userObj) {
        return showAvatar(userObj, 150)
      },
      resetSportCompetitions(competition) {
        this.inProgressCompetitions = competition.sport_competitions.in_progress_sport_competitions
        this.finishedCompetitions = competition.sport_competitions.finished_sport_competitions
      },
      resetDeclinedRequests(competitionId) {
        this.$set(this.declinedRequests, competitionId,  1)
      },
      openDeclineCompetitionModal(competition) {
        this.$modal.show(DeclineSportCompetition,
          {
            competitionId: competition.id
          },
          {
            pivotX: (this.$mq === "sm" ? 0 : 0.5),
            width: (this.$mq === "sm" ? "100%" : 600)
          }
        )
      },
      finishedNicknameCss(userId, winnerId) {
        if (userId == winnerId) {
          return 'winner-nickname'
        } else {
          return 'loser-nickname'
        }
      },
      acceptCompetition(competition) {
        axios
          .post(`/api/v1/sport_competitions/accepts`,
            {
              id: competition.id
            }
          )
          .then(response => {
            showAlert(I18n.t("users.edit_form.success_notification"))

            this.$set(this.successFullRequests, competition.id,  1)
          })
          .catch(error => {
            this.errors = error.response.data[0]
            console.log(error)
          })
      }
    }
  }
</script>

<style scoped>
  .sport-competitions {
    padding: 0px 15px;
    padding-top: 65px;

    @media only screen and (max-width: 600px) {
      padding-bottom: 24px;
    }
  }

  .page-title-requests {
    display: flex;
    align-items: baseline;

    @media only screen and (max-width: 600px) {
      justify-content: space-between;
    }
  }

  .user-partner-item {
    margin-right: 24px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
  }

  .user-partner-item a {
    text-decoration: none;
    margin-right: 36px;
    font-size: 13px;
  }

  .user-partner-avatar-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  .winner-nickname {
    color: black;
  }

  .loser-nickname {
    color: grey
  }

  .sport-alert-item-wrap {
    background-color: #7d79b9;
    height: 40px;
    width: 45px;
    border-radius: 10px;
    position: relative;
    margin-right: 12px;
  }

  .sport-alert-item {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .competition-started svg {
    color: #0b6135;
    margin-right: 3px;
  }

  .competition-declined svg {
    color: #dc3545;
    margin-right: 3px;
  }

  .mini-badminton {
    background-image: url("../../images/mini-image-badminton.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-squash {
    background-image: url("../../images/mini-image-squash.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-football {
    background: url("../../images/mini-image-football.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-basketball {
    background: url("../../images/mini-image-basketball.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-tennis {
    background: url("../../images/mini-image-tennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-tabletennis {
    background: url("../../images/mini-image-tabletennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
    left: 12px !important;
  }

  .opponents {
    @media only screen and (min-width: 600px) {
      margin-top: 24px;
    }
  }
</style>
