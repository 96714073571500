<template>
  <div class="third-step">
    <div class="step-counters"><div class="step-counters">{{ I18n.t("registration.current_step", { current_step_number: 3 }) }}</div></div>
    <div class="success-notification-title">
      {{ I18n.t("registration.third_step.info_text") }}
      <div class="second-line">
        {{ I18n.t("registration.third_step.description") }}<br>
      </div>
    </div>

    <div class="errors" v-if="this.errors">
      {{ this.errors }}
    </div>
    <div style="margin-top: 56px;">
      <div class="card-body">
        <img :src="imgPath()" class="card-img avatar" />
        <div class="card-img-overlay">
          <button class="btn btn-primary btn-sm" id="pick-avatar">
            {{ I18n.t("users.edit_form.change_avatar_button") }}
          </button>
        </div>
      </div>  
      <avatar-cropper
        @uploaded="handleUploaded"
        trigger="#pick-avatar"
        requestMethod="PUT"
        :upload-url="`/api/v1/users/avatars/${this.user_local.id}`"
        upload-form-name="avatar"
        :upload-headers="avatarUploadHeaders()" />
    </div>

    <vue-form-generator :model="user"
                        :schema="this.formSchema"
                        :options="this.formOptions"
                        ref="formSchema"
                        />

    <nav class="navbar fixed-bottom navbar-light bg-light registration-buttons-wrap" v-if="$mq === 'sm' && !loading">
      <div class="buttons-wrap">
        <button class="btn cta-btn grey skip-button" style="margin-right: 10px;" v-on:click.prevent="updateStep(4)">
          {{ I18n.t("registration.buttons.skip_step") }}
        </button>
        <button class="btn cta-btn purple next-button" v-on:click.prevent="submitAction()">
          <font-awesome-icon icon="arrow-circle-right" size="1x" />
          {{ I18n.t("registration.third_step.buttons.next") }}
        </button>
      </div>
    </nav>

    <div class="buttons-wrap" v-if="$mq !== 'sm' && !loading">
      <button class="btn cta-btn grey skip-button" style="margin-right: 10px;" v-on:click.prevent="updateStep(4)">
        {{ I18n.t("registration.buttons.skip_step") }}
      </button>
      <button class="btn cta-btn purple next-button" v-on:click.prevent="submitAction()">
        <font-awesome-icon icon="arrow-circle-right" size="1x" />
        {{ I18n.t("registration.third_step.buttons.next") }}
      </button>
    </div>

    <div v-if="loading">
      <div style="padding-top: 24px; text-align: center;">
        <img src="../../images/loading.gif" style="width: 80px;">
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  import updateStepMixin from "../mixins/update_step"
  import showAvatar from "../../utils/avatar"
  import { validators } from "vue-form-generator"

  export default {
    props: {
      user: Object
    },
    data: function() {
      return {
        user_local: this.user,
        errors: null,
        loading: false,
        formSchema: {
          fields: [
           {
              type: "input",
              label: I18n.t("users.edit_form.labels.name"),
              inputType: "string",
              model: "name",
              styleClasses: "col-xs-6",
              required: false,
              validator: validators.string,
              selectOptions: {
                noneSelectedText: I18n.t("users.edit_form.non_selected_text.name"),
              },
            },
            {
              type: "input",
              label: I18n.t("users.edit_form.labels.surname"),
              inputType: "string",
              model: "surname",
              styleClasses: "col-xs-6",
              required: false,
              validator: validators.string,
              selectOptions: {
                noneSelectedText: I18n.t("users.edit_form.non_selected_text.surname"),
              },
            },
            {
              type: "input",
              label: I18n.t("users.edit_form.labels.nickname"),
              inputType: "string",
              model: "nickname",
              styleClasses: "col-xs-6",
              required: false,
              validator: validators.string,
              selectOptions: {
                noneSelectedText: I18n.t("users.edit_form.non_selected_text.nickname"),
              },
            },
          ]
        },
        formOptions: {
          validationErrorClass: "has-error",
          validationSuccessClass: "has-success",
          validateAfterChanged: true,
        },
      }
    },
    mixins: [updateStepMixin],
    methods: {
      avatarUploadHeaders() {
        return { "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content") }
      },
      handleUploaded(resp) {
        this.user_local = resp.user;
      },
      imgPath() {
        const size = 200

        return showAvatar(this.user_local, size)
      },
      submitAction() {
        this.errors = false
        this.loading = true

        axios
          .put(`/api/v1/users/${this.user_local.id}`, { user: this.user_local })
          .then(response => {
            this.updateStep(4)
          })
          .catch(error => {
            this.errors = true
            this.loading = false
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>

<style scoped>
  .third-step {
    font-size: 14px;
    padding-top: 65px;

    @media only screen and (max-width: 600px) {
      padding: 0px 15px;
      padding-top: 65px;
      padding-bottom: 90px;
    }
  }

  .success-notification-title {
    line-height: 1.3;
    margin-bottom: -60px;
    font-size: 18px;
    margin-top: 12px;
    font-weight: 500;
    line-height: 1;

    @media only screen and (min-width: 600px) {
      margin-top: 24px;
    }
  }

  .second-line {
    font-size: 15px;
    line-height: 1.3;
    color: #6f6e6e;
    font-weight: 400;
    margin-top: 6px;

    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }

  .errors {
    color: #e65328;
    text-align: center;
    padding-bottom: 20px;
  }

  .buttons-wrap {
    text-align: center;
    width: 100%;
    margin-top: 30px;

    @media only screen and (max-width: 600px) {
      display: flex;
      justify-content: space-around;
      margin-top: 0px;
    }
  }

  .registration-buttons-wrap {
    @media only screen and (max-width: 600px) {
      justify-content: space-around;
    }
  }

  .buttons-wrap {
    max-width: 800px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .cta-btn.grey {
    color: grey;
  }

  .card-body {
    position: relative;
    text-align: center;
  }

  .avatar {
    width: 200px;
    border-radius: 6px;
    margin: 20px auto;
  }

  .card-img-overlay {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 70%;
    left: 0;
    padding: 1.25rem;
  }

  .buttons-wrap .skip-button {
    width: 48%;
    margin-right: 12px;

    @media only screen and (min-width: 600px) {
      width: 40%;
    }
  }

  .buttons-wrap .next-button {
    width: 50%;

    @media only screen and (min-width: 600px) {
      width: 40%;
    }
  }
</style>
