<template>
  <div class="events-list">
    <div class="page-title" style="display: flex; justify-content: space-between;">
      <div>
        {{ I18n.t("sport_events.index.title") }}
      </div>
      
      <!-- <div v-if="this.currentGeo" style="font-size: 12px; color: grey; font-weight: 400; margin-top: 6px;"> {{ this.currentGeo }}</div>-->
      <div class="sport-events-list-link" v-if="this.current_user.taken_part_events_count > 0">
        <a :href="`/users/${this.current_user.id}/sport_events`" class="profile-navigation-link ">
          {{ this.myEventsLinkText() }}
          <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L6 5.73684L1 10" stroke="#FFCB37" stroke-width="2"/>
          </svg>
        </a>
      </div>    
    </div>

    <div class="events-list__link-buttons">
      <!-- <div class="events-list__users-button">
        <a class="top-link" href="/users">
          <font-awesome-icon icon="users" size="lg" />
          {{ I18n.t("sport_events.users_button") }}
        </a>
      </div> -->

      <!-- <div class="events-list__settings-button" v-if="!current_user.is_guest">
        <a class="top-link" href="/user_settings/sports">
          <font-awesome-icon icon="cogs" size="lg" />
          {{ I18n.t("sport_events.settings_button") }}
        </a>
      </div> -->
    </div>

    <div class="events-list__filters">
      <!-- <div class="events-list__header__datepicker">
        <div class="icon">
          <font-awesome-icon icon="calendar-alt" size="lg" />
        </div>
        <div class="events-list__header__datepicker__wrapper">
          <input
            type="text"
            id="datepicker-trigger"
            placeholder="Select dates"
            :value="localDate"
            readonly="readonly"
          >
          <AirbnbStyleDatepicker
            :trigger-element-id="'datepicker-trigger'"
            :mode="'single'"
            :fullscreen-mobile="true"
            :months-to-show="1"
            :offset-x="this.pickerXOffset"
            :offset-y="this.pickerYOffset"
            :date-one="localDate"
            @date-one-selected="dateSelected"
          />
        </div>
      </div> -->
      <!-- <div class="events-list__header__location" v-if="current_user.is_guest">
        <div class="icon">
          <font-awesome-icon icon="map-marker-alt" size="lg" />
        </div>
        <select class="custom-select" v-model="searchByCityId" @change="loadEvents()">
          <option v-for="city in cities" v-bind:value="city.id">
            {{ city.name }}
          </option>
        </select>
      </div> -->
    </div>

    <div class="events-list__loading-items" v-if="loading">
      <img src="../../images/loading.gif" loading="lazy">
    </div>

    <div class="events-list__items" v-else>
      <event-list-item v-for="sport_event in localEvents.date_events" :key="sport_event.id" :sport_event="sport_event" :show_date="true"/>
    </div>

    <div class="events-list__items-not-found" v-if="localEvents.date_events.length == 0">
      <div class="events-list__items-not-found-text">{{ I18n.t("sport_events.not_found") }}</div>

      <a href="/sport_events/new" class="btn cta-btn purple btn-sm">
        + {{ I18n.t("sport_events.index.new_button")}}
      </a>
    </div>

    <div class="events_additional-list-wrap" v-if="localEvents.additional_dates_events.length > 0 && !loading">
      <h5>{{ I18n.t("sport_events.additional_items.index.title") }}</h5>
      <div class="events-list__items" >
        <event-list-item v-for="sport_event in localEvents.additional_dates_events" :key="sport_event.id" :sport_event="sport_event" :show_date="true" />
      </div>
    </div>

    <div class="events_successful-list-wrap" v-if="localEvents.latest_successfull_events.length > 0 && !loading">
      <div class="page-title">{{ I18n.t("sport_events.success_items.index.title") }}</div>
      <div class="events-list__items" >
        <event-list-item v-for="sport_event in localEvents.latest_successfull_events" :key="sport_event.id" :sport_event="sport_event" :show_date="true" />
      </div>
    </div>

    <modals-container />
  </div>
</template>

<script>
  import axios from "axios"

  export default {
    props: {
      sport_events: Object,
      cities: Array,
      current_city_id: Number,
      current_user: Object,
      current_geo: String,
      date: String,
    },
    data() {
      return {
        searchByCityId: this.current_city_id,
        dateFormat: 'D MMM',
        localDate: this.date,
        currentGeo: this.current_geo,
        localEvents: this.sport_events,
        loading: false,
        manuallyChangeDate: false,
        pickerXOffset: null,
        pickerYOffset: null
      }
    },
    methods: {
      loadEvents() {
        this.loading = true

        axios
          .get('/api/v1/sport_events.json', {
            params: { date: this.localDate, city_id: this.searchByCityId }
          })
          .then(response => {
            this.localEvents = response.data
            this.loading = false
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => (this.loading = false));
      },
      dateSelected(date) {
        this.localDate = date

        if (this.manuallyChangeDate) {
          this.loadEvents()
        }

        if (!this.manuallyChangeDate) {
          this.manuallyChangeDate = true
        }
      },
      myEventsLinkText() {
        return I18n.t("users.profile.current_user_event_link")
      },
    },
  }
</script>

<style scoped>
  .events-list {
    font-size: 14px;

    padding: 0px 15px;
    padding-top: 65px;

    @media only screen and (max-width: 600px) {
      font-size: 13px;
      padding-bottom: 60px;
    }
  }

  .events-list__link-buttons {
    display: flex;
    justify-content: flex-end;

    @media only screen and (max-width: 600px) {
      justify-content: space-between;
    }
  }

  .events-list__settings-button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
    margin-left: 15px;
  }

  .page-title {
    font-size: 15px;
    font-weight: 500;
    padding: 10px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, .05);

    @media only screen and (min-width: 600px) {
      font-size: 16px;
    }
  }

  /*btn*/
  .top-link {
    color: #1d2d35;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
  }

  .events-list__items {
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
  }

  .events_additional-list-wrap {
    margin-top: 30px;
  }

  .events_additional-list-wrap h5 {
    text-align: center;
    margin-bottom: 20px;
  }

  .events-list__loading-items {
    text-align: center;
  }

  .events-list__items-not-found {
    text-align: center;
    margin-top: 50px;

    @media only screen and (max-width: 600px) {
      padding-left: 0px;
    }
  }

  .events-list__items-not-found-text {
    line-height: 1.2;
    margin-bottom: 15px;
  }

  .cta-btn.green {
    background-color: #0c6035;
    color: #fff;
  }

  .events-list__filters {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 600px) {
      align-items: flex-start;
    }
  }

  .events_successful-list-wrap {
    margin-top: 48px;
  }

  .events-list__header__location {
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: center;

    @media only screen and (max-width: 600px) {
      justify-content: center;
      width: 100%;
    }
  }

  .events-list__header__datepicker {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .events-list__header__datepicker__wrapper {
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .events-list__header__datepicker input {
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.12);
    line-height: 18px;
    padding: 8px 12px;
    max-width: 150px;
    height: 32px;

    @media only screen and (max-width: 600px) {
      width: 100%;
      max-width: none;
    }
  }

  .events-list__header__location select {
    height: 32px;
    max-width: 150px;
    padding: 0.175rem 1.75rem 0.375rem 0.75rem;

    @media only screen and (max-width: 600px) {
      width: 100%;
      max-width: none;
    }
  }

  .events-list__header__location .icon {
    color: #672323;
  }

  .icon {
    margin-right: 4px;
  }

  .events-list h2 {
    font-size: 2.07692308rem !important;

    @media only screen and (max-width: 600px) {
      font-size: 1.77692308rem !important;
    }

    margin: 12px 0px;
  }

  .profile-navigation-link {
    text-decoration: none;
    font-weight: 450;
    font-size: 13px;
    cursor: pointer;
  }

  .sport-events-list-link {
  }

  .sport-events-list-link svg {
    margin-left: 5px;
  }
</style>
