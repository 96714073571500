<template>
  <div class="new-competition" data-slideout-ignore>
    <a class="back-link" :href="`/users/${this.compete_with_user.nickname}`" style="text-decoration: none;">
      <div class="back-link-wrap" v-if="$mq == 'lg'">
        <font-awesome-icon icon="arrow-left" size="sm" />
        <span style="font-size: 14px; font-weight: 400;">{{ I18n.t("common.buttons.back") }}</span>
      </div>
    </a>

    <div class="competition-title" v-if="$mq != 'sm'">
      {{ I18n.t('sport_competitions.index.titles.new', { nickname: compete_with_user.nickname  }) }}
    </div>

    <div>
      <div style="display: flex;">
        <user-list-avatar :user="compete_with_user" />

        <div class="user-sports">
          <div class="user-sports-links" v-for="(level, sport, index) in compete_with_user.sports">
            <div class="user-sport-item">
              <div class="sport-item__image" :class="`user-${sport}`"></div>
              <div class="sport-item__level"> {{ level }}</div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-top: 6px; font-size: 13px;" v-if="compete_with_user.finished_competitions_count > 0">
        {{ I18n.t('users.count_of_competitions', { number: compete_with_user.finished_competitions_count }) }}
      </div>
    </div>

    <div style="margin-top: 24px;">
      <a href="" v-on:click.prevent.stop="openExplanationModal" style="color: #4d4d12;">
        как это работает?
      </a>
    </div>

    <div class="errors" v-if="this.errors">
      {{ this.errors }}
    </div>

    <div style="margin-top: 24px;">
      <label>{{ I18n.t("competitions.new_form.labels.sport") }}</label><br>
      <ul class="sports">
        <li class="sport" v-for="sport in sports">
          <a v-on:click.prevent.stop="sportSelected(sport)" :class="sportSelectedCss(sport)" href="" class="sport__link">
            <div :class="sportImageCss(sport)" class="sport-image"></div>
            <div style="padding: 0px 12px;">{{ I18n.t(`sports.${sport.code}`) }}</div>
          </a>
        </li>
      </ul>

      <vue-form-generator :model="localCompetition"
                          :schema="this.formSchema"
                          :options="this.formOptions"
                          ref="formSchema"
                          />
    </div>

    <div style="padding-top: 24px; text-align: center;" v-if="loading">
      <img src="../../images/loading.gif" loading="lazy" style="width: 80px;">
    </div>

    <div class="submit-button-wrap" v-else>
      <a class="btn cta-btn purple btn-sm" v-on:click.prevent="submitAction()">
        {{ I18n.t("competitions.new_form.buttons.submit") }}
      </a>
    </div>

    <modals-container />
  </div>
</template>

<script>
  import CompetitionExplanationModal from "../../components/sport_competitions/modals/explanation.vue"

  import { validators } from "vue-form-generator"
  import axios from "axios"
  import showAlert from "../../utils/alert"

  export default {
    props: {
      compete_with_user: Object,
      sports: Array,
    },
    data: function() {
      return {
        errors: null,
        loading: false,
        localCompetition: { count_of_wins: 3 },
        sport_id: this.sports[0].id,
        formSchema: {
          fields: [
            {
              type: "input",
              label: I18n.t("competitions.new_form.labels.count_of_wins"),
              inputType: "string",
              model: "count_of_wins",
              styleClasses: "col-xs-6",
              required: true,
              validator: validators.string,
              selectOptions: {
                noneSelectedText: I18n.t("competitions.new_form.non_selected_text.count_of_wins"),
              },
            },
          ]
        },
        formOptions: {
          validationErrorClass: "has-error",
          validationSuccessClass: "has-success",
          validateAfterChanged: true,
        },
      }
    },
    methods: {
      sportSelected(sport) {
        this.sport_id = sport.id
      },
      sportSelectedCss(sport) {
        if (this.sport_id == sport.id)
          return "active"

        return ""
      },
      sportImageCss(sport) {
        return `${sport.code}`
      },
      openExplanationModal() {
        this.$modal.show(CompetitionExplanationModal,
          {},
          {
            pivotX: (this.$mq === "sm" ? 0 : 0.4),
            width: (this.$mq === "sm" ? "100%" : 600),
            height: "70%"
          }
        )
      },
      submitAction() {
        this.errors = null

        axios
          .post("/sport_competitions/",
            {
              sport_competition: {
                sport_id: this.sport_id,
                requested_user_id: this.compete_with_user.id,
                count_of_wins: this.localCompetition.count_of_wins
              }
            }
          )
          .then(response => {
            showAlert(I18n.t("users.edit_form.success_notification"))

            Turbolinks.visit(`/users/${this.compete_with_user.nickname}`)
            // redirect to user and show there info that waiting request from user
          })
          .catch(error => {
            this.errors = error.response.data[0]
            console.log(error)
          })
      }
    }
  }
</script>

<style scoped>
  .new-competition {
    font-size: 14px;
    padding: 0px 15px;
    padding-top: 65px;

    @media only screen and (max-width: 600px) {
      padding-bottom: 60px;
      font-size: 13px;
    }
  }

  .errors {
    color: red;
  }

  .competition-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 24px;

    @media only screen and (min-width: 600px) {
      margin-top: 24px;
    }
  }

  .cta-btn {
    display: inline-block;
    padding: 4px 15px;
    line-height: 30px;
    text-decoration: none;
  }

  .cta-btn.purple {
    background-color: #6460ac;
    color: #fff;
  }

  .cta-btn.purple:hover {
    background-color: #5b459c;
    color: #fff;
  }

  .submit-button-wrap {
    margin-top: 24px;
  }

  ul.sports {
    margin: 0;
    margin-bottom: 12px;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
    list-style-type: none;
  }

  ul.sports::-webkit-scrollbar {
    width: 0;
  }

  ul.sports li:not(:first-child) {
    margin-left: .5rem;
  }

  ul.sports li a.active {
    border: 2px solid #6460ac;
    border-radius: 5px;
  }

  ul.sports li {
    position: relative;
    height: 7.2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  ul.sports li .sport__link {
    width: 6.5rem;
    height: 7.5rem;
    background: #fff;
    border: 1px solid #e0e0e0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #333;
    text-decoration: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 0.8rem;
    font-weight: 600;

    @media only screen and (max-width: 600px) {
      width: 5.5rem;
      height: 7rem;
    }
  }

  ul.sports li .day__weekday {
    flex-basis: 1rem;
    font-size: .7rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  ul.sports li .day__monthday {
    margin-top: -1rem;
    font-size: 1.8rem;
    color: #333;
    line-height: 2.5rem;
  }

  ul.sports li .day__name {
    margin-top: -1rem;
    font-size: 0.9rem;
    color: #333;
    line-height: 2.5rem;
    margin: auto;
  }

  ul.sports li .day__month {
    font-size: .7rem;
    color: #333;
  }

  .sport-image {
    width: 35px;
    height: 35px;
    margin-bottom: 12px;
  }

  .user-sports {
    display: flex;
    margin-left: 30px;
    margin-top: 10px;
    flex-wrap: wrap;
    flex-basis: 100%;

    @media only screen and (max-width: 600px) {
      margin-left: 0px;
    }
  }

  .user-sport-item {
    margin-right: 24px;
    position: relative;
    background-color: #7d79b9;
    height: 40px;
    width: 40px;
    border-radius: 10px;

    @media only screen and (max-width: 600px) {
      height: 35px;
      width: 35px;
      margin-right: 18px;
    }
  }

  .user-sport-item--small {
    position: relative;
    background-color: #7d79b9;
    border-radius: 10px;
    height: 35px;
    width: 35px;
    margin-right: 18px;
    margin-left: 12px;

    @media only screen and (max-width: 600px) {
      margin-left: 6px;
    }
  }

  .sport-item__image {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 10px;
    left: 10px;

    @media only screen and (max-width: 600px) {
      height: 20px;
      width: 20px;
      top: 8px;
      left: 8px;
    }
  }

  .sport-item__level {
    position: absolute;
    bottom: -4px;
    right: -5px;
    font-size: 11px;
    color: black;
    background-color: #F9DB85;
    font-weight: bold;
    border-radius: 20px;
    height: 15px;
    width: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .badminton {
    background-image: url("../../images/mini-image-badminton.svg");
    background-repeat: no-repeat;
    background-size: 35px 35px;
  }

  .squash {
    background-image: url("../../images/mini-image-squash.svg");
    background-repeat: no-repeat;
    background-size: 35px 35px;
  }

  .football {
    background: url("../../images/mini-image-football.svg");
    background-repeat: no-repeat;
    background-size: 35px 35px;
  }

  .basketball {
    background: url("../../images/mini-image-basketball.svg");
    background-repeat: no-repeat;
    background-size: 35px 35px;
  }

  .tennis {
    background: url("../../images/mini-image-tennis.svg");
    background-repeat: no-repeat;
    background-size: 35px 35px;
  }

  .tabletennis {
    background: url("../../images/mini-image-tabletennis.svg");
    background-repeat: no-repeat;
    background-size: 35px 35px;
  }

  .volleyball {
    background: url("../../images/mini-image-volleyball.svg");
    background-repeat: no-repeat;
    background-size: 35px 35px;
  }

  .user-badminton {
    background-image: url("../../images/mini-image-badminton.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .user-squash {
    background-image: url("../../images/mini-image-squash.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .user-football {
    background: url("../../images/mini-image-football.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .user-basketball {
    background: url("../../images/mini-image-basketball.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .user-tennis {
    background: url("../../images/mini-image-tennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .user-tabletennis {
    background: url("../../images/mini-image-tabletennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .user-volleyball {
    background: url("../../images/mini-image-volleyball.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }
</style>
