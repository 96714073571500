<template>
  <a :href="this.notification.data.sport_event_url" class="notification-item-wrap notification-item-wrap_message">
    <div v-if="!!this.notification.sport_event_name" class="event-name">
      <a :href="this.notification.data.sport_event_url">
        {{ this.notification.sport_event_name }}
      </a>
    </div>

    <div class="notification-item">
      <div class="notification-item-message">
        <span class="notification-item-img">
          <a :href="this.notification.data.author_url">
            <img :src="this.notification.data.author_image" loading="lazy" v-if="!!this.notification.data.author_image">
            <span v-else class="nickname">@{{ this.notification.data.author_nickname }}:</span>
          </a>
        </span>
        <i>{{ this.notification.data.message }} </i>
      </div>
    </div>
  </a>
</template>

<script>
  export default {
    props: {
      notification: Object,
    }
  }
</script>

<style scoped>
  .status {
    padding: 2px;
  }

  .notification-item-message i {
    margin-right: 5px;
    margin-left: 5px;
  }
</style>
