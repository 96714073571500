<template>
  <div class="sport-schedule" :class="{ skip: isInvisibile }">
    <div class="sport-schedule-inputs">
      <div class="sport-schedule-inputs__week-day">
        <select v-on:change="checkValidity" class="custom-select" v-bind:name="selectName('week_day')" v-model="sport_schedule.week_day">
          <option disabled value="">{{ I18n.t("sport_schedule.week_day") }}</option>
          <option v-for="day in week_days" v-bind:value="day">
            {{ I18n.t(`common.week_days.${day}`) }}
          </option>
        </select>
      </div>
      <div class="sport-schedule-inputs__time">
        <select v-on:change="checkValidity" class="custom-select" v-bind:name="selectName('time_from')" v-model.number="sport_schedule.time_from">
          <option disabled value="">{{ I18n.t("sport_schedule.time_from") }}</option>
          <option v-for="hour in timeRange(minimum_time)" v-bind:value="hour">
            {{ hourOptionName(hour) }}
          </option>
        </select>
        <select v-on:change="checkValidity" class="custom-select" v-bind:name="selectName('time_to')" v-model.number="sport_schedule.time_to">
          <option disabled value="">{{ I18n.t("sport_schedule.time_to") }}</option>
          <option v-for="hour in timeRange(sport_schedule.time_from + 1)" v-if="sport_schedule.time_from" v-bind:value="hour">
            {{ hourOptionName(hour) }}
          </option>
        </select>
      </div>
      <input v-bind:name="selectName('id')" v-bind:value="sport_schedule.id" v-if="sport_schedule.id !== null" type="hidden">
      <input v-bind:name="selectName('_destroy')" value="1" v-if="sport_schedule._destroy" type="hidden">
      <input v-bind:name="selectName('sport_id')" v-bind:value="sport_id" type="hidden">
      <div style="margin-top: 16px;">
        <a class="text-danger" style="font-size: 15px;" @click.prevent="removeSportSchedule(index)">{{ I18n.t("common.buttons.remove_schedule") }}</a>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
  import _ from "lodash"

  const MINIMUM_TIME = 6
  const MAXIMUM_TIME = 24

  export default {
    props: {
      sport_id: Number,
      index: Number,
      sport_schedule: Object,
    },
    data: function() {
      return {
        week_days: ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
        minimum_time: MINIMUM_TIME
      }
    },
    computed: {
      isInvisibile() {
        if (this.sport_schedule._destroy)
          return true
        return false
      }
    },
    methods: {
      timeRange(fromTime) {
        let result = []

        for (let a = fromTime; a <= MAXIMUM_TIME; a++) {
          result.push(a)
        }

        return result
      },
      hourOptionName: function(hour) {
        return `${I18n.t("datetime.distance_in_words.x_hours", { count: hour })}`
      },
      selectName: function(code) {
        return `user[user_sport_schedules_attributes][][${code}]`
      },
      checkValidity: function() {
        this.$emit("check-sport-schedules-validity")
      },
      removeSportSchedule: function(index) {
        this.$emit("remove-sport-schedule", index)
      }
    }
  }
</script>

<style scoped>
  .skip {
    display: none;
  }
  .sport-schedule-inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-direction: column;
    font-size: 16px;
  }
  .sport-schedule-inputs__time {
    display: flex;
    margin-top: 10px;
  }
</style>
