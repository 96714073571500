<template>
  <div class="fifth-step">
    <div class="step-counters"><div class="step-counters">{{ I18n.t("registration.current_step", { current_step_number: 4 }) }}</div></div>

    <div class="success-notification-title">
      {{ I18n.t("registration.fourth_step.info_text") }}

      <div class="second-line">
        {{ I18n.t("registration.fourth_step.description") }}
      </div>
    </div>

    <div style="margin-top: 120px; margin-bottom: 80px;">
      <div class="telegram-link" v-if="!this.telegram_enabled">
        <a target="_blank" href="https://t.me/SpottySpoBot">t.me/spottybot</a>
      </div>

      <div class="success-telegram-message alert alert-success" v-if="this.telegram_enabled">
        <font-awesome-icon icon="check-circle" size="1x" />
        {{ I18n.t("registration.fourth_step.success_description") }}
      </div>
    </div>

    <div class="form-checkbox form-group" id="ready-to-play-checkbox">
      <div class="pretty p-svg p-curve">
        <input type="checkbox" v-model="ready_to_play" />
        <div class="state p-success">
            <!-- svg path -->
            <svg class="svg svg-icon" viewBox="0 0 20 20">
                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style="stroke: white;fill:white;"></path>
            </svg>
            <label>{{ I18n.t("registration.fourth_step.ready_to_play") }}</label>
        </div>
      </div>
    </div>

    <nav class="navbar fixed-bottom navbar-light bg-light registration-buttons-wrap" v-if="$mq === 'sm' && !loading">
      <div class="buttons-wrap">
        <button class="btn cta-btn purple" v-on:click.prevent="submitAction()">
          {{ I18n.t("registration.fourth_step.buttons.finish") }}
        </button>
      </div>
    </nav>

    <div class="buttons-wrap" v-if="$mq !== 'sm' && !loading">
      <button class="btn cta-btn purple" v-on:click.prevent="submitAction()">
        {{ I18n.t("registration.fourth_step.buttons.finish") }}
      </button>
    </div>

    <div v-if="loading">
      <div style="padding-top: 24px; text-align: center;">
        <img src="../../images/loading.gif" style="width: 80px;">
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  import updateStepMixin from "../mixins/update_step"

  export default {
    props: {
      telegram_enabled: { type: Boolean, default: true },
      user: { type: Object }
    },
    data: function() {
      return {
        loading: false,
        ready_to_play: true
      }
    },
    mixins: [updateStepMixin],
    methods: {
      submitAction() {
        if (this.ready_to_play) {
          this.user.play_status = "ready"
        } else {
          this.user.play_status_updated_at = new Date()
        }

        this.loading = true

        axios
          .put(`/api/v1/users/${this.user.id}`, { user: this.user })
          .then(response => {
            this.updateStep(5)
          })
          .catch(error => {
            this.loading = false
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>

<style scoped>
  .fifth-step {
    font-size: 14px;
    padding-top: 65px;

    @media only screen and (max-width: 600px) {
      padding: 0px 15px;
      padding-top: 65px;
    }
  }

  .success-notification-title {
    line-height: 1.3;
    margin-bottom: -60px;
    font-size: 18px;
    margin-top: 12px;
    font-weight: 500;
    line-height: 1;

    @media only screen and (min-width: 600px) {
      margin-top: 24px;
    }
  }

  .buttons-wrap {
    margin-top: 16px;
    width: 100%;

    @media only screen and (max-width: 600px) {
      display: flex;
      justify-content: space-around;
      margin-top: 0px;
    }
  }

  .buttons-wrap button {
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  #ready-to-play-checkbox {
    @media only screen and (max-width: 600px) {
      position: fixed;
      bottom: 60px;
      width: 100%;
      z-index: 1000;
    }
  }

  .second-line {
    font-size: 15px;
    line-height: 1.3;
    color: #6f6e6e;
    margin-top: 10px;
    margin-bottom: 72px;
    font-weight: 400;

    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }

  .success-telegram-message {
    text-align: center;

    @media only screen and (min-width: 600px) {
      width: 40%;
    }
  }

  .telegram-link {
    margin-bottom: 36px;
  }

  .telegram-link a {
    color: #007bff;
    font-size: 16px;
  }

  .cta-btn.grey {
    color: grey;
  }
</style>
