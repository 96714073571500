<template>
  <div class="in-progress-competition">
    <div style="display: flex; align-items: center;">
      <div style="display: flex; align-items: center;">
        <a :href="`/users/${competition.requested_user.nickname}`" style="margin-right: 32px; text-align: center; font-size: 12px; text-decoration: none;">
          <img :src="imgPath(competition.requested_user)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
          <div style="margin-top: 3px;">
            @{{ competition.requested_user.nickname }}
          </div>
        </a>
        <div style="font-size: 30px">
          {{ competition.requested_user_wins }}
        </div>
      </div>
      <div style="display: flex; align-items: center; margin-right: 6px; margin-left: 6px;">:</div>
      <div style="display: flex; align-items: center; margin-right: 24px;">
        <div style="font-size: 30px">
          {{ competition.initiated_user_wins }}
        </div>
        <a :href="`/users/${competition.initiator.nickname}`" style="margin-left: 32px;  text-align: center; font-size: 12px; text-decoration: none;">
          <img :src="imgPath(competition.initiator)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
          <div style="margin-top: 3px;">
            @{{ competition.initiator.nickname }}
          </div>
        </a>
      </div>

      <div style="padding-left: 24px; border-left: 1px solid #dcdcdc;">
        <div class="sport-alert-item-wrap" style="margin-right: 24px; width: 40px;">
          <div :class="`sport-alert-item mini-${competition.sport_code}`" style="width: 20px; height: 20px;"></div>
        </div>

        <div style="font-size: 13px; color: grey; margin-top: 6px;">
          {{ I18n.t('sport_competitions.index.till_wins', { wins_count: competition.count_of_wins }) }}
        </div>
      </div>
    </div>

    <div style="margin-top: 16px; display: flex; align-items: center;">
      <div style="margin-right: 16px;" v-if="localCompetition.unresolved_results.length == 0">
        <button class="btn cta-btn purple btn-sm" style="border-radius: 0.4rem; font-size: 13px; font-weight: 500;" @click="openAddResultModal(competition)">
          {{ I18n.t('sport_competitions.index.add_result') }}
        </button>
      </div>

      <div>
        <a :href="`/sport_competitions/${competition.id}`" class="light-link">
          {{ I18n.t('sport_competitions.index.more_info_link') }}
        </a>
      </div>
    </div>

    <div v-if="localCompetition.unresolved_results.length > 0">
      <div v-for="result in localCompetition.unresolved_results" :key="result.id">
        <sport-competition-result-submission :key="result.id" :currentUserId="currentUserId" :result="result" />
      </div>
    </div>

    <modals-container />
  </div>
</template>

<script>
  import axios from "axios"
  import showAlert from "../../../utils/alert"
  import showAvatar from "../../../utils/avatar"

  import AddResultModal from "../../../components/users/competitions/add_result_modal.vue"

  export default {
    props: {
      currentUserId: Number,
      competition: Object
    },
    data() {
      return {
        localCompetition: this.competition
      }
    },
    created() {
      this.$eventHub.$on("result-submitted", this.resetSportCompetitions)
    },
    methods: {
      imgPath(userObj) {
        return showAvatar(userObj, 150)
      },
      resetSportCompetitions(response) {
        console.log("RESP", response)
        console.log('local competition id', this.localCompetition.id)

        if (response.sport_competition.status == 'in_progress' && response.sport_competition.id == this.localCompetition.id) {
          this.localCompetition = response.sport_competition
        }

        console.log('local competition', this.localCompetition)
      },
      openAddResultModal(competition) {
        this.$modal.show(AddResultModal,
          {
            userId: this.currentUserId,
            result: competition.new_result,
            countOfWins: competition.count_of_wins,
            sportCode: competition.sport_code

          },
          {
            pivotX: (this.$mq === "sm" ? 0 : 0.5),
            width: (this.$mq === "sm" ? "100%" : 600)
          }
        )
      },
    }
  }
</script>

<style scoped>
  .current-user-sport-competitions {
  }

  .page-title-requests {
    display: flex;
    align-items: baseline;

    @media only screen and (max-width: 600px) {
      justify-content: space-between;
    }
  }

  .user-partner-avatar-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  .sport-alert-item-wrap {
    background-color: #7d79b9;
    height: 40px;
    width: 45px;
    border-radius: 10px;
    position: relative;
    margin-right: 12px;
  }

  .sport-alert-item {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .mini-badminton {
    background-image: url("../../../images/mini-image-badminton.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-squash {
    background-image: url("../../../images/mini-image-squash.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-football {
    background: url("../../../images/mini-image-football.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-basketball {
    background: url("../../../images/mini-image-basketball.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-tennis {
    background: url("../../../images/mini-image-tennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-volleyball {
    background: url("../../../images/mini-image-volleyball.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-tabletennis {
    background: url("../../../images/mini-image-tabletennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
    left: 12px !important;
  }
</style>
