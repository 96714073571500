<template>
  <div class="competition-explanation">
    <div class="title" style="margin-left: 6px;">
      {{ I18n.t("sport_competitions.modals.titles.explanation") }}
    </div>

    <div>
      <p>
        {{ I18n.t("sport_competitions.explanation.first_block") }}
      </p>

      <p>
        {{ I18n.t("sport_competitions.explanation.second_block") }}
      </p>

      <p v-html="I18n.t('sport_competitions.explanation.third_block')">
      </p>

      <p v-html="I18n.t('sport_competitions.explanation.fourth_block')">
      </p>
    </div>

    <div style="position: absolute; bottom: 36px;" v-on:click.prevent.stop="$emit('close')">
      <a href="" class="btn cta-btn btn-sm cancel" v-on:click.prevent.stop="$emit('close')">
        {{ I18n.t("common.buttons.got_it") }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompetitionsExplanation',
}
</script>

<style scoped>
  .competition-explanation {
    padding: 20px;
  }

  .competition-explanation p {
    font-size: 15px;
    line-height: 1.2;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 36px;
  }

  .cta-btn {
    display: inline-block;
    padding: 4px 15px;
    line-height: 30px;
    text-decoration: none;
  }

  .cta-btn.green {
    background-color: #0c6035;
    color: #fff;
  }

  .cta-btn.cancel {
    color: grey;
    font-size: 14px;
    border: 1px solid grey;
  }

</style>
