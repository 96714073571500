<template>
  <div class="ios-add-to-home-screen-popup">
    <div class="ios-add-to-home-screen-popup-title-wrap">
      <div class="logo"></div>
      <div class="ios-add-to-home-screen-popup-title" style="margin-left: 6px;">
        {{ I18n.t("sport_events.modals.add_to_home_screen_popup_title") }}
      </div>
    </div>

    <div class="ios-add-to-home-screen-popup-text">
      {{ I18n.t("sport_events.modals.add_to_home_screen_popup_text") }}
    </div>

    <div class="ios-add-to-home-screen-popup-title" style="margin-top: 24px; font-size: 17px; margin-bottom: 12px;">
      {{ I18n.t("sport_events.modals.add_to_home_screen_popup_instructions_title") }}
    </div>

    <div class="ios-add-to-home-screen-popup-instructions">
      <div style="display: flex; align-items: center; margin-bottom: 12px;">
        <div class="share-button-safari"></div>
        {{ I18n.t("sport_events.modals.add_to_home_screen_popup_first_rule") }}
      </div>
      <div style="display: flex; align-items: center;">
        <div class="add-to-screen-button-ios"></div>
        {{ I18n.t("sport_events.modals.add_to_home_screen_popup_second_rule") }}
     </div>
     <div class="add-to-screen-button-ios-screenshot"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddIosApp'
}
</script>

<style scoped>
  .ios-add-to-home-screen-popup {
    padding: 20px;
  }

  .ios-add-to-home-screen-popup-title-wrap {
    display: flex;
    align-items: center;
  }

  .ios-add-to-home-screen-popup-title {
    font-size: 20px;
    font-weight: 600;
  }

  .ios-add-to-home-screen-popup-text {
    font-size: 16px;
    line-height: 20px;
    margin-top: 24px;
  }

  .logo {
    background-image: url("../../../images/ios-logo.svg");
    background-repeat: no-repeat;
    background-size: 30px 30px;
    width: 30px;
    height: 30px;
  }

  .share-button-safari {
    background-image: url("../../../images/share-button-safari.svg");
    background-repeat: no-repeat;
    background-size: 30px 30px;
    width: 40px;
    height: 30px;
    margin-right: 12px;
  }

  .add-to-screen-button-ios {
    background-image: url("../../../images/add-to-screen-button-ios.svg");
    background-repeat: no-repeat;
    background-size: 30px 30px;
    width: 40px;
    height: 30px;
    margin-right: 12px;
  }

  .add-to-screen-button-ios-screenshot {
    background-image: url("../../../images/add-to-screen-button-ios-screenshot.png");
    background-repeat: no-repeat;
    background-size: 150px 200px;
    width: 150px;
    height: 200px;
    margin-top: 18px;
  }
</style>
