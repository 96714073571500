<template>
  <div class="first-step">
    <div class="congratulations-image">
      <img class="avatar" src="../../images/congratulations.svg" height="100" width="100">
    </div>

    <h2 style="text-align: center;">{{ I18n.t("registration.first_step.congratulation") }}</h2>
    <div class="success-notification-title">
      {{ I18n.t("registration.first_step.success_text") }}<br><br>
      <p style="color: black;" v-if="!email_confirmed">
        {{ I18n.t("registration.first_step.email_confirmation_info") }}
      </p>
    </div>
    <p>
      {{ I18n.t("registration.first_step.first_paragraph") }}<br><br>
      {{ I18n.t("registration.first_step.second_paragraph") }}
    </p>

    <div class="buttons-wrap">
      <button class="btn cta-btn grey" style="margin-right: 24px; width: 46%;" v-on:click.prevent="updateStep(6)">
        {{ I18n.t("registration.first_step.buttons.skip") }}
      </button>
      <button class="btn cta-btn purple" style="width: 46%;" v-on:click.prevent="updateStep(2)">
        <font-awesome-icon icon="arrow-circle-right" size="1x" />
        {{ I18n.t("registration.first_step.buttons.lets_go") }}
      </button>
    </div>
  </div>
</template>

<script>
  import updateStepMixin from "../mixins/update_step"

  export default {
    props: {
      email_confirmed: Boolean,
    },
    data: function() {
      return {
      }
    },
    mixins: [updateStepMixin],
  }
</script>

<style scoped>
  .first-step {
    font-size: 14px;

    padding: 0px 15px;
    padding-top: 65px;

    @media only screen and (max-width: 600px) {
    }
  }

  .congratulations-image {
    text-align: center;
    margin-bottom: 12px;
    @media only screen and (max-width: 600px) {
      margin-top: 30px;
    }
  }

  .first-step h4 {
    text-align: center;
  }

  .first-step p {
    padding: 10px 20%;
    font-size: 17px;
    line-height: 1.3;

    @media only screen and (max-width: 600px) {
      font-size: 16px;
      padding: 10px 8%;
    }
  }

  .success-notification-title {
    text-align: center;
    font-size: 18px;

    @media only screen and (max-width: 600px) {
      font-size: 17px;
    }
  }

  .buttons-wrap {
    text-align: center;
    margin-top: 24px;
    padding: 10px 20%;

    @media only screen and (max-width: 600px) {
      display: flex;
      justify-content: space-around;
      margin-top: 24px;
      padding: 10px 8%;
    }
  }

  .cta-btn.grey {
    color: grey;
  }
</style>
