<template>
  <div class="second-step">
    <div class="step-counters">{{ I18n.t("registration.current_step", { current_step_number: 2 }) }}</div>
    <div class="success-notification-title">
      {{ I18n.t("registration.second_step.info_text") }}
    </div>

    <div class="sports-wrap">
      <sports-list :sports="this.sports" :user_sports="{}" :sport_schedules="[]"  :registration="true"></sports-list>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      sports: Array
    },
    data: function() {
      return {
      }
    },
  }
</script>

<style scoped>
  .second-step {
    font-size: 14px;
    padding-top: 65px;

    @media only screen and (max-width: 600px) {
      padding: 0px 15px;
      padding-top: 65px;
    }
  }

  .success-notification-title {
    line-height: 1.3;
    margin-bottom: -60px;
    font-size: 18px;
    margin-top: 12px;
    font-weight: 500;
    line-height: 1;

    @media only screen and (min-width: 600px) {
      margin-top: 24px;
    }
  }

  .sports-wrap {
    padding-bottom: 100px;
  }
</style>
