<template>
  <div>
    <div class="user-avatar-modal-wrap">
      <div class="user-avatar-modal">
        <img :src="imgPath(user)" width="350" height="350" alt="profile photo" class="avatar-icon">
      </div>
    </div>
  </div>
  
</template>

<script>
import showAvatar from "../../../utils/avatar"

export default {
  props: {
    user: Object
  },
  data() {
    return {
    }
  },
  mounted() {
    document.querySelector('.v--modal-overlay').style.background = 'rgba(0, 0, 0, .8)'
  },
  unmounted() {
    document.querySelector('.v--modal-overlay').style.background = 'rgba(0, 0, 0, .2)'
  },
  methods: {
    showName(user) {
      if (user.nickname.includes("spotty_") && this.fullName(user))
        return this.fullName(user)

      return `@${user.nickname}`
    },
    fullName(user) {
      if (user.name || user.surname)
        return `${user.name ? user.name : ''} ${user.surname ? user.surname : ''}`;
    },
    imgPath(user) {
      const size = 350

      return showAvatar(user, size)
    }
  }
}
</script>

<style scoped>
  .user-avatar-modal-wrap {
    height: 80%;
    background: rgb(0 0 0 / 80%);

    width: 80%;

    @media only screen and (max-width: 600px) {
      text-align: center;
      width: 100%;
    }
  }
</style>