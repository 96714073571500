<template>
  <div class="events-new" data-slideout-ignore>
    <form-wizard :title="formTitle()" :subtitle="formInfo()" :nextButtonText="nextButtonText()" :backButtonText="backButtonText()" :finishButtonText="finishButtonText()" @on-complete="onFormComplete" color="#f9db85" :start-index="copied_event ? 1 : 0">
      <tab-content :title="firstTabTitle()" :before-change="validateFirstTab">
        <vue-form-generator :model="sportEvent"
                            :schema="this.firstTabSchema"
                            :options="this.formOptions"
                            ref="firstTabForm"
                            />

        <div class="checkbox-label">{{ I18n.t("sport_events.new_form.sport_level") }}</div>
        <div class="form-check form-check-inline" v-for="sport_level in sport_levels">
          <div class="pretty p-svg p-curve p-bigger">
            <input type="checkbox" :value="sport_level" v-model="sportEvent.sport_levels" />
            <div class="state p-success">
              <svg class="svg svg-icon" viewBox="0 0 20 20">
                  <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style="stroke: white;fill:white;"></path>
              </svg>
              <label>{{ sport_level }}</label>
            </div>
          </div>
        </div>
        <div class="levels-hint">
          A: {{ I18n.t("sport_events.levels.a") }}<br>
          B: {{ I18n.t("sport_events.levels.b") }}<br>
          C: {{ I18n.t("sport_events.levels.c") }}<br>
          D: {{ I18n.t("sport_events.levels.d") }}
        </div>
      </tab-content>
      <tab-content :title="secondTabTitle()" :before-change="validateSecondTab">
        <div class="form-group field-input" >
          <label for="-">
            <span>
              {{ I18n.t("sport_events.new_form.sport_complex")}}
            </span>
          </label>

          <ul class="places" v-if="this.sportEvent.previous_places.length > 0">
            <li class="place" v-for="place in this.sportEvent.previous_places">
              <a v-on:click.prevent.stop="changePlace(place)" :class="placeSelectedCss(place)" href="" class="place__link">
                <div class="place__title">
                  {{ place.place_name.substring(0,16) }}
                </div>

                <div class="place__address">
                  {{ place.formatted_address }}
                </div>

                <div class="place__distance" v-if="place.distance">
                  {{ I18n.t("sport_events.edit.distance_from_you", { distance: place.distance.toFixed(1) }) }}
                </div>

                <div class="place__more-info">
                  <a href="" class="light-link" v-on:click.prevent.stop="openPlaceInfoModal(place)">{{ I18n.t("sport_event.show_link") }} </a>
                </div>
              </a>
            </li>
          </ul>

          <div class="place-selector">
            <a class="btn-sm cta-btn" v-on:click.prevent="openNewPlaceModal()">
              {{ I18n.t("sport_events.edit.add_new_place") }}
            </a>
          </div>

          <div v-if="this.placeSelected === false" style="color: rgb(139, 0, 0);">{{ I18n.t("sport_events.errors.choose_court") }}</div>
        </div>

       <div class="col-xs-12">
          <label>{{ I18n.t("sport_events.new_form.labels.date") }}</label><br>

          <ul class="days">
            <li class="day" v-for="day in daysForSelect">
              <a v-on:click.prevent.stop="dateSelected(day)" :class="dateSelectedCss(day)" href="" class="day__link" v-if="isToday(day)">
                <div class="day__name">
                  {{ I18n.t("common.days_from_now.today") }}
                </div>
              </a>
              <a v-on:click.prevent.stop="dateSelected(day)" :class="dateSelectedCss(day)" href="" class="day__link" v-else-if="isTommorow(day)">
                <div class="day__name">
                  {{ I18n.t("common.days_from_now.tomorrow") }}
                </div>
              </a>
              <a v-on:click.prevent.stop="dateSelected(day)" :class="dateSelectedCss(day)" href="" class="day__link" v-else>
                <div class="day__weekday">
                  {{ I18n.strftime(day, "%a") }}
                </div>

                <div class="day__monthday">
                  {{ I18n.strftime(day, "%d") }}
                </div>

                <div class="day__month">
                  {{ I18n.strftime(day, "%b") }}
                </div>
              </a>
            </li>
          </ul>
          <!-- <input
            type="text"
            id="datepicker-trigger"
            class="col-md-4 form-control"
            :placeholder="datePlaceholder()"
            :value="event_date"
            readonly="readonly"
          >
          <AirbnbStyleDatepicker
            :trigger-element-id="'datepicker-trigger'"
            :mode="'single'"
            :fullscreen-mobile="true"
            :months-to-show="1"
            :offset-x="this.pickerXOffset"
            :offset-y="this.pickerYOffset"
            :min-date="this.minDate()"
            :date-one="event_date"
            @date-one-selected="dateSelected"
          /> -->
        </div>

        <vue-form-generator :model="sportEvent"
                            :schema="this.secondTabSchema"
                            :options="this.formOptions"
                            ref="secondTabForm"
                            />

        <div class="vue-form-generator">
          <div class="form-group field-input">
            <div class="pretty p-svg p-curve p-bigger">
              <input type="checkbox" v-model="sportEvent.booked" />
              <div class="state p-success">
                <svg class="svg svg-icon" viewBox="0 0 20 20">
                    <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style="stroke: white;fill:white;"></path>
                </svg>
                <label>{{ I18n.t("sport_events.new_form.labels.booked") }}</label>
              </div>
            </div>
            <div class="hint">{{ I18n.t("sport_events.new_form.hints.booked") }}</div>
          </div>
        </div>
       </tab-content>
       <tab-content :title="thirdTabTitle()">
          <div class="errors" v-if="this.errors">
            {{ this.errors }}
          </div>
          <vue-form-generator :model="sportEvent"
                              :schema="this.thirdTabSchema"
                              :options="this.formOptions"
                              ref="thirdTabForm"
                              />

          <div v-if="this.sport_event.participants.length > 0" style="margin: 24px 0px;">
            <div class="checkbox-label-participants">{{ I18n.t("sport_events.new_form.participants") }}</div>

            <div style="color: grey;">
              {{ I18n.t("sport_events.new_form.selected_participants_count", { selected_count: this.sportEvent.selected_participants.length, all_count: this.sport_event.participants.length}) }}
            </div>

            <ul class="list-group list-group-flush">
              <li class="list-group-item" style="border: none;" v-for="(participant, index) in this.sport_event.participants" v-if="[0,1].includes(index)">
                <div class="list-item-wrap">
                  <div class="list-user-item-wrap">
                    <img class="avatar" :src="imgPath(participant)" height="45" width="45" loading="lazy">
                    <span style="color: black; font-weight: 600; padding-left: 6px;">@{{ participant.nickname }}</span>

                    <div class="user-play-status-wrap" style="text-align: right;">
                      <div class="user-play-status" style="cursor: pointer;">
                        <font-awesome-icon icon="circle" size="xs" :class="`play_status_${participant.play_status}`" />
                        {{ I18n.t(`users.play_status.${participant.play_status}`) }}
                      </div>
                    </div>
                  </div>

                  <label class="switch ">
                    <input type="checkbox" class="default" :value="participant.id" v-model="sportEvent.selected_participants">
                    <span class="slider round"></span>
                  </label>
                </div>
              </li>
            </ul>

            <div v-if="this.sport_event.participants.length > 2" style="margin-top: 12px;">
              <a href="" class="light-link" v-on:click.prevent.stop="openParticipantsModal()">
                {{ I18n.t("sport_events.new_form.more_participants_link") }}
              </a>
            </div>
          </div>

          <div v-if="sportEvent.selected_participants.length > 0">
            <div style="margin-bottom: 36px;">
              <div class="pretty p-svg p-curve p-bigger">
                <input type="checkbox" v-model="sportEvent.only_for_selected_partners" />
                <div class="state p-success">
                  <svg class="svg svg-icon" viewBox="0 0 20 20">
                      <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style="stroke: white;fill:white;"></path>
                  </svg>
                  <label>{{ I18n.t("sport_events.new_form.labels.only_for_selected_partners") }}</label>
                </div>
              </div>
<!--
              <div class="levels-hint">
                другие пользователи не будут иметь доступа к событию
              </div> -->
            </div>
          </div>

          <div v-if="commonNotifyCount > 0" class="notify-message-wrapper">
            {{ I18n.t("sport_events.new_form.notifying_users.message", { count: this.commonNotifyCount }) }}
          </div>
       </tab-content>
    </form-wizard>
    <modals-container />
  </div>
</template>

<script>
  import { validators } from "vue-form-generator"
  import axios from "axios"
  import SportEventNewPlace from "../../components/sport_events/modals/new_place.vue"
  import SportEventPlaceInfo from "../../components/sport_events/modals/place_info.vue"
  import SportEventPreviousParticipants from "../../components/sport_events/modals/previous_event_participants.vue"
  import showAvatar from "../../utils/avatar"

  export default {
    props: {
      sports: Array,
      currency: String,
      sport_levels: Array,
      sport_event: Object,
      copied_event: { type: Boolean, default: false }
    },
    data() {
      return {
        sportEvent: { selected_participants: [], ...this.sport_event },
        errors: null,
        subscribersCountLoading: false,
        potentialSubscribersIds: [],
        pickerXOffset: null,
        pickerYOffset: null,
        event_date: this.minDate(),
        placeSelected: null,
        formOptions: {
          validationErrorClass: "has-error",
          validationSuccessClass: "has-success",
          validateAfterChanged: true,
        },
        firstTabSchema: {
         fields: [
           {
              type: "select",
              label: I18n.t("sport_events.new_form.labels.sport"),
              model: "sport_id",
              values: this.sports,
              styleClasses: "col-xs-6",
              required: true,
              validator: validators.number,
              selectOptions: {
                name: "name",
                noneSelectedText: I18n.t("sport_events.new_form.non_selected_text.sport"),
              },
           },
           {
              type: "input",
              inputType: "number",
              label: I18n.t("sport_events.new_form.labels.minimal_participants"),
              model: "minimal_participants_count",
              placeholder: "",
              validator: validators.number,
              min: 2,
              max: 22,
              required: true,
            },
          ]
        },
        secondTabSchema: {
          fields: [
           {
              type: "select",
              label: I18n.t("sport_events.new_form.labels.start_at_hour"),
              model: "start_at_hour",
              values: this.hoursRange,
              styleClasses: "col-xs-12",
              required: true,
              validator: validators.number,
              selectOptions: {
                noneSelectedText: I18n.t("sport_events.new_form.non_selected_text.start_at_hour"),
              },
           },
           {
              type: "select",
              label: I18n.t("sport_events.new_form.labels.start_at_minutes"),
              model: "start_at_minutes",
              values: [{ name: "00", id: 0 }, { name: "30", id: 30 }],
              styleClasses: "col-xs-12",
              required: true,
              validator: validators.number,
              selectOptions: {
                noneSelectedText: I18n.t("sport_events.new_form.non_selected_text.start_at_minutes"),
              },
            },
            {
              type: "select",
              label: I18n.t("sport_events.new_form.labels.durability_minutes"),
              model: "durability_minutes",
              values: [
                { name: "0.5", id: 30 },
                { name: "1", id: 60 },
                { name: "1.5", id: 90 },
                { name: "2", id: 120 },
              ],
              hint: I18n.t("sport_events.new_form.hints.durability_minutes"),
              styleClasses: "col-xs-12",
              required: true,
              validator: validators.number,
              selectOptions: {
                noneSelectedText: I18n.t("sport_events.new_form.non_selected_text.durability_minutes"),
              },
            },
          ],
        },
        thirdTabSchema: {
          fields: [
           {
              type: "input",
              inputType: "number",
              label: this.priceLabel(),
              model: "price",
              hint: I18n.t("sport_events.new_form.hints.price"),
              placeholder: "",
              validator: validators.number,
              required: false,
            },
            {
              type: "textArea",
              inputType: "string",
              label: this.commentLabel(),
              model: "comment",
              placeholder: "",
              required: false,
            },
          ]
        },
      }
    },
    watch: {
      "sportEvent.selected_participants": function (newSportEventParticipants, oldSportEventParticipants) {
        if (newSportEventParticipants.length == 0)
          this.sportEvent.only_for_selected_partners = false
      }
    },
    created() {
      this.$eventHub.$on("new-sport-event-place", this.resetSportPlaces)
      this.$eventHub.$on("change-sport-event-form-participants", this.resetSportEventParticipants)
      this.$eventHub.$on("on-validate", this.afterValidation)
    },
    mounted() {
      this.setUpDatepickerCoords()
      let today = this.minDate()

      this.sportEvent.start_at_year = today.getFullYear()
      this.sportEvent.start_at_month = today.getMonth() + 1
      this.sportEvent.start_at_day = today.getDate()

      if (!this.$refs.autocomplete) {
        return
      }

      this.autocomplete = new google.maps.places.Autocomplete(
        (this.$refs.autocomplete),
        { types: ["address"], language: "en" }
      );

      this.autocomplete.addListener("place_changed", () => {
        let place = this.autocomplete.getPlace();

        let ac = place.address_components;
        let lat = place.geometry.location.lat();
        let lon = place.geometry.location.lng();

        let cityName = _.find(place.address_components, function(o) {
          return _.isEqual(o.types, ["locality", "political"]) ||
                   _.isEqual(o.types, ["administrative_area_level_3", "political"]) ||
                       _.isEqual(o.types, ["postal_town"])
        });

        this.sportEvent.latitude = lat;
        this.sportEvent.longitude = lon;
        this.sportEvent.place_id = place.place_id;
        this.sportEvent.formatted_address = place.formatted_address;

        if(cityName) {
          this.sportEvent.city_name = cityName["short_name"];
        }
      });
    },
    computed: {
      commonNotifyCount() {
        if (this.sportEvent.only_for_selected_partners)
          return this.sportEvent.selected_participants.length

        return this.potentialSubscribersIds.filter(x => !this.sportEvent.selected_participants.includes(x)).length + this.sportEvent.selected_participants.length
      },
      daysForSelect() {
        let today = this.minDate()
        let days = [today]

        for(var i = 1; i < 8; i++) {
          let newDate = new Date()
          newDate.setDate(today.getDate() + i)
          days.push(newDate)
        }

        return days
      }
    },
    methods: {
      resetSportPlaces(emmittedSportPlace) {
        this.sportEvent.selected_place_id = emmittedSportPlace.id
        this.sportEvent.previous_places.unshift(emmittedSportPlace)
      },
      resetSportEventParticipants(emmittedParticipants) {
        this.sportEvent.selected_participants = emmittedParticipants
      },
      openNewPlaceModal() {
        this.$modal.show(SportEventNewPlace,
          {
            countryCoords: this.sportEvent.country_coords
          },
          {
            height: "auto",
            pivotX: (this.$mq === "sm" ? 0 : 0.5),
            width: (this.$mq === "sm" ? "100%" : 600)
          }
        )
      },
      openPlaceInfoModal(place) {
        this.$modal.show(SportEventPlaceInfo,
          {
            sportEventPlaceLatitude: place.latitude,
            sportEventPlaceLongitude: place.longitude,
            sportEventName: place.place_name
          },
          {
            pivotX: (this.$mq === "sm" ? 0 : 0.5),
            width: (this.$mq === "sm" ? "100%" : 600)
          }
        )
      },
      openParticipantsModal() {
        this.$modal.show(SportEventPreviousParticipants,
          {
            participants: this.sport_event.participants,
            selectedParticipants: this.sportEvent.selected_participants
          },
          {
            pivotX: (this.$mq === "sm" ? 0 : 0.5),
            width: (this.$mq === "sm" ? "100%" : 600),
            resizable: true,
            minHeight: 600,
            adaptive: true
          }
        )
      },
      changePlace(place) {
        this.sportEvent.selected_place_id = place.id
      },
      placeSelectedCss(place) {
        if (this.sportEvent.selected_place_id == place.id)
          return "active"

        return ""
      },
      imgPath(partitipant) {
        return showAvatar(partitipant, 45)
      },
      setUpDatepickerCoords() {
        const datepickerCoords = document.body.getBoundingClientRect()

        const pageDiff = datepickerCoords.width - 1700
        this.pickerXOffset = -450 - pageDiff / 2
        this.pickerYOffset = 400
      },
      datePlaceholder() {
        return I18n.t("sport_events.new_form.placeholders.date")
      },
      formTitle() {
        return I18n.t("sport_events.new_form.title")
      },
      formInfo() {
        return I18n.t("sport_events.new_form.form_info")
      },
      firstTabTitle() {
        return I18n.t("sport_events.new_form.first_tab_title")
      },
      secondTabTitle() {
        return I18n.t("sport_events.new_form.second_tab_title")
      },
      thirdTabTitle() {
        return I18n.t("sport_events.new_form.third_tab_title")
      },
      validateFirstTab() {
        return this.$refs.firstTabForm.validate()
      },
      validateSecondTab() {
        this.subscribersInfoLoading = true

        axios
          .post("/api/v1/sport_events/notifying_user_ids", { sport_event: this.sportEvent })
          .then(response => {
            this.subscribersInfoLoading = false
            this.potentialSubscribersIds = response.data.ids
          })
          .catch(error => {

          })

        const isPlaceSelected = ((!!this.sportEvent.selected_place_id && !this.sportEvent.place_name && !this.sportEvent.formatted_address) || (!!this.sportEvent.place_name && !!this.sportEvent.formatted_address))

        this.placeSelected = isPlaceSelected

        return isPlaceSelected && !!this.event_date && this.$refs.secondTabForm.validate()
      },
      validateThirdTab() {
        return this.$refs.thirdTabForm.validate()
      },
      isToday(date) {
        const today = new Date()

        return date.getDate() == today.getDate() &&
               date.getMonth() == today.getMonth() &&
               date.getFullYear() == today.getFullYear()
      },
      isTommorow(date) {
        const today = new Date()
        const dateTomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)

        return date.getDate() == dateTomorrow.getDate() &&
               date.getMonth() == dateTomorrow.getMonth() &&
               date.getFullYear() == dateTomorrow.getFullYear()
      },
      minDate() {
        let today = new Date()

        if (today.getHours() > 16) {
          // tommorow
          today.setDate(today.getDate() + 1);
        }

        return today
      },
      dateSelected(date) {
        this.event_date = date
        const dateObj = new Date(date)
        this.sportEvent.start_at_year = dateObj.getFullYear()
        this.sportEvent.start_at_month = dateObj.getMonth() + 1
        this.sportEvent.start_at_day = dateObj.getDate()
      },
      dateSelectedCss(date) {
        if (this.event_date.getDate() == date.getDate())
          return "active"

        return ""
      },
      nextButtonText() {
        return I18n.t("vue_form_generator.buttons.next")
      },
      backButtonText() {
        return I18n.t("vue_form_generator.buttons.back")
      },
      finishButtonText() {
        return I18n.t("vue_form_generator.buttons.finish")
      },
      hoursRange() {
        const today = new Date()

        let lowEnd = 0
        let highEnd = 23

        if (!!this.event_date) {
          const eventDateObj = new Date(this.event_date)

          if (today.toDateString() == eventDateObj.toDateString()) {
            lowEnd = today.getHours() + 1

            if (lowEnd > 23) {
              lowEnd = 23
            }
          }
        } else {
          highEnd = 0
        }

        let arr = []

        if (highEnd == 0) {
          return arr
        }

        for (let i = lowEnd; i <= highEnd; i++) {
          arr.push({ name: ("0" + i).slice(-2), id: i })
        }

        return arr
      },
      priceLabel() {
        return I18n.t("sport_events.new_form.labels.price", { currency: this.currency })
      },
      commentLabel() {
        return I18n.t("sport_events.new_form.labels.comment")
      },
      afterValidation(result, tabIndex) {
        console.log("AFTER VALIDATION", result, tabIndex)
      },
      onFormComplete() {
        this.errors = null

        axios
          .post("/api/v1/sport_events", { sport_event: this.sportEvent })
          .then(response => {
            const { sport_event_id } = response.data
            window.location = `/sport_events/${sport_event_id}`
          })
          .catch(error => {
            this.errors = error.response.data[0]
          })
      }
    }
  }
</script>

<style scoped>
  .events-new {
    font-size: 14px;
    padding: 0px 15px;
    padding-top: 65px;

    @media only screen and (max-width: 600px) {
      font-size: 13px;
      padding-bottom: 60px;
    }
  }

  .form-check-inline {
    margin-right: 0px;
  }

  .vue-form-generator .form-control {
    font-size: 14px;

    @media only screen and (max-width: 600px) {
      font-size: 13px;
    }
  }

  ul.days {
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
    list-style-type: none;
  }

  ul.days::-webkit-scrollbar {
    width: 0;
  }

  ul.days li:not(:first-child) {
    margin-left: .5rem;
  }

  ul.days li a.active {
    border: 2px solid #6460ac;
    border-radius: 5px;
  }

  ul.days li {
    position: relative;
    height: 7.2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  ul.days li .day__link {
    width: 5.2rem;
    height: 6rem;
    background: #fff;
    border: 1px solid #e0e0e0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #333;
    text-decoration: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 1.1rem;
    font-weight: 700;
  }

  ul.days li .day__weekday {
    flex-basis: 1rem;
    font-size: .7rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  ul.days li .day__monthday {
    margin-top: -1rem;
    font-size: 1.8rem;
    color: #333;
    line-height: 2.5rem;
  }

  ul.days li .day__name {
    margin-top: -1rem;
    font-size: 0.9rem;
    color: #333;
    line-height: 2.5rem;
    margin: auto;
  }

  ul.days li .day__month {
    font-size: .7rem;
    color: #333;
  }

  .checkbox-label {
    margin-bottom: 4px;
  }

  #datepicker-trigger {
    background-color: #fff;
  }

  .checkbox-label-participants {
    font-size: 14px;
    color: grey;
    margin-bottom: 12px;
  }

  .checkbox-label-participants-selected {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .list-group-item img {
    border-radius: 20px;
  }

  .list-group-item {
    padding: 0.75rem 0em;
  }

  .list-item-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d6d6d6;
    border-radius: 10px;
    padding: 10px;
  }

  .list-user-item-wrap {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    height: 80px;
    width: fit-content;
    margin-bottom: 6px;
  }

  .list-user-item-wrap span {
    font-size: 13px;
    margin-top: 4px;
  }

  .list-user-item-wrap img {
    margin-top: 3px;
  }

  .errors {
    color: #e65328;
    text-align: center;
    padding-bottom: 20px;
  }

  .field-wrap {
    margin-bottom: 1rem;
  }

  .nickname-label {
    color: blue;
  }

  .custom-select {
    margin-right: 10px;
  }

  .place-selector {
    align-items: center;
    display: flex;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .place-selector-new-button {
    margin-left: 10px;
  }

  .place-selector-new-button svg {
    font-size: 25px;
    color: black;
  }

  .notify-message-wrapper {
    color: grey;
    font-size: 13px;
    margin-bottom: 20px;
    text-align: center;

    @media only screen and (max-width: 600px) {
      font-size: 12px;
      margin-bottom: 15px;
    }
  }

  .cta-btn {
    display: inline-block;
    padding: 4px 15px;
    line-height: 30px;
    text-decoration: none;
  }

  /* switches */

  /* The switch - the box around the slider */

  .switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 30px;
    float:right;
  }

  /* Hide default HTML checkbox */
  .switch input {display:none;}

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 23px;
    width: 23px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input.default:checked + .slider {
    background-color: #6460ac;
  }
  input.primary:checked + .slider {
    background-color: #2196F3;
  }
  input.success:checked + .slider {
    background-color: #8bc34a;
  }
  input.info:checked + .slider {
    background-color: #3de0f5;
  }
  input.warning:checked + .slider {
    background-color: #FFC107;
  }
  input.danger:checked + .slider {
    background-color: #f44336;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(23px);
    -ms-transform: translateX(23px);
    transform: translateX(23px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .levels-hint {
    margin-top: 5px;
    font-style: italic;
    font-size: .8em;
  }

  /* Places selector */
  ul.places {
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
    list-style-type: none;
    margin-bottom: -20px;
  }

  ul.places::-webkit-scrollbar {
    width: 0;
  }

  ul.places li:not(:first-child) {
    margin-left: .5rem;
  }

  ul.places li a.active {
    border: 2px solid #6460ac;
    border-radius: 5px;
  }

  ul.places li {
    position: relative;
    height: 8rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  ul.places li .place__link {
    width: 22rem;
    height: 7rem;
    background: #fff;
    border: 1px solid #e0e0e0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #333;
    text-decoration: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 1.1rem;
    font-weight: 700;

    @media only screen and (max-width: 600px) {
      width: 17rem;
    }
  }

  ul.places li .place__title {
    flex-basis: 40px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }

  ul.places li .place__address {
    margin-top: -1rem;
    font-size: 13px;
    color: #909499;
    font-weight: 400;

    @media only screen and (max-width: 600px) {
      font-size: 13px;
    }
  }

  ul.places li .place__distance {
    font-size: .7rem;
    color: #7558a7;
    margin-top: 12px;
    padding: 5px;
    background-color: #eeebff;
    border-radius: 5px;
  }

  .place-selector a {
    background: white;
    border: 1px solid #d8d8d8;
    font-size: 13px;
    font-weight: 450;
    color: black;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
      font-size: 12px;
    }
  }

  .place__more-info {
    margin-top: 6px;
  }

  .user-play-status {
    font-size: 14px;
    min-width: 125px;
    text-align: left;
    margin-left: 6px;
    margin-top: 6px;
    color: grey;

    @media only screen and (max-width: 600px) {
      font-size: 12px;
    }
  }
</style>
