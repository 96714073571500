<template>
  <div class="edit-event">
    <a class="back-link" :href="`/sport_events/${this.localSportEvent.id}`" style="text-decoration: none;">
      <div class="back-link-wrap" v-if="$mq == 'lg'">
        <font-awesome-icon icon="arrow-left" size="sm" />
        <span style="font-size: 14px; font-weight: 400;">{{ I18n.t("common.buttons.back") }}</span>
      </div>
    </a>

    <div class="edit-event-booking edit-event-block">
      <div class="title-wrap">
        <div class="title">{{ I18n.t("sport_events.edit.booking_title") }}</div>
      </div>

      <div class="switch-wrap">
        <div class="switch-label">зал забронирован?</div>
        <div>
          <label class="switch">
            <input type="checkbox" class="default" v-model="localSportEvent.booked">
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <div class="booking-telephone" v-if="!!this.localSportEvent.telephone && !this.disableBook">
        <a :href="`tel:${this.localSportEvent.telephone}`">{{ this.localSportEvent.telephone }}</a>
      </div>

      <div class="submit-button-wrap">
        <button href="" class="btn cta-btn purple btn-sm" disabled v-if="this.disableBook">
          {{ I18n.t("common.buttons.save") }}
        </button>

        <a href="" class="btn cta-btn purple btn-sm" v-on:click.prevent.stop="bookCourt()" v-else>
          {{ I18n.t("common.buttons.save") }}
        </a>
      </div>
    </div>

    <div class="edit-event-place edit-event-block">
      <div class="title-wrap" style="display: flex; justify-content: space-between;">
        <div class="title">{{ I18n.t("sport_events.edit.court_title") }}</div>

        <div class="place-selector">
          <a class="btn-sm cta-btn" v-on:click.prevent="openNewPlaceModal()">
            {{ I18n.t("sport_events.edit.add_new_place") }}
          </a>
        </div>
      </div>

      <div class="errors-wrap" v-if="placeErrors.length > 0">
        <div class="errors" v-for="error in placeErrors" >
          {{ error }}
        </div>
      </div>

      <ul class="places" style="margin-top: 24px;">
        <li class="place" v-for="place in localSportEvent.places">
          <a v-on:click.prevent.stop="changePlace(place)" :class="placeSelectedCss(place)" href="" class="place__link">
            <div class="place__title">
              {{ place.place_name.substring(0,16) }}
            </div>

            <div class="place__address">
              {{ place.formatted_address }}
            </div>

            <div class="place__distance" v-if="place.distance">
              {{ I18n.t("sport_events.edit.distance_from_you", { distance: place.distance.toFixed(1) }) }}
            </div>

            <div class="place__more-info">
              <a href="" class="light-link" v-on:click.prevent.stop="openPlaceInfoModal(place)">{{ I18n.t("sport_event.show_link") }} </a>
            </div>
          </a>
        </li>
      </ul>

      <div class="title-description">
        <div>
          <font-awesome-icon icon="exclamation-circle" size="lg" />
          {{ I18n.t("sport_events.edit.title_description") }}
        </div>
      </div>

      <div class="submit-button-wrap">
        <a href="" class="btn cta-btn purple btn-sm" v-on:click.prevent.stop="changeSportEventPlace()">
          {{ I18n.t("common.buttons.save") }}
        </a>
      </div>
    </div>

    <div class="edit-event-time edit-event-block">
      <div class="title-wrap">
        <div class="title">{{ I18n.t("sport_events.edit.title") }}</div>
      </div>
      <div class="errors-wrap" v-if="errors.length > 0">
        <div class="errors" v-for="error in errors" >
          {{ error }}
        </div>
      </div>
      <div class="select-block">
        <div class="select-wrap">
          <label>
            {{ I18n.t("sport_events.new_form.labels.start_at_hour") }}
          </label>
          <select  class="custom-select" v-model.number="localSportEvent.start_at_hour">
            <option v-for="h in this.hoursRange()" v-bind:value="h.id">
              {{ h.name }}
            </option>
          </select>
        </div>

        <div class="select-wrap">
          <label>
            {{ I18n.t("sport_events.new_form.labels.start_at_minutes") }}
          </label>
          <select  class="custom-select" v-model.number="localSportEvent.start_at_minutes">
            <option v-for="h in this.minutesRange()" v-bind:value="h.id">
              {{ h.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="title-description">
        <div>
          <font-awesome-icon icon="exclamation-circle" size="lg" />
          {{ I18n.t("sport_events.edit.title_description") }}
        </div>
      </div>

      <div class="submit-button-wrap">
        <a href="" class="btn cta-btn purple btn-sm" v-on:click.prevent.stop="changeSportEvent()">
          {{ I18n.t("common.buttons.save") }}
        </a>
      </div>
    </div>

    <modals-container />
  </div>
</template>

<script>
  import axios from "axios"
  import showAlert from "../../utils/alert"
  import SportEventNewPlace from "../../components/sport_events/modals/new_place.vue"
  import SportEventPlaceInfo from "../../components/sport_events/modals/place_info.vue"

  export default {
    props: {
      sport_event: Object,
    },
    data: function() {
      return {
        localSportEvent: this.sport_event,
        disableBook: this.sport_event.booked,
        selectedPlaceId: this.sport_event.selected_place_id,
        errors: [],
        placeErrors: []
      }
    },
    created() {
      this.$eventHub.$on("new-sport-event-place", this.resetSportPlaces)
    },
    methods: {
      resetSportPlaces(emmittedSportPlace) {
        this.selectedPlaceId = emmittedSportPlace.id
        this.localSportEvent.places.unshift(emmittedSportPlace)
      },
      minutesRange() {
        return [{ name: "00", id: 0 }, { name: "30", id: 30 }]
      },
      openPlaceInfoModal(place) {
        this.$modal.show(SportEventPlaceInfo,
          {
            sportEventPlaceLatitude: place.latitude,
            sportEventPlaceLongitude: place.longitude,
            sportEventName: place.place_name
          },
          {
            pivotX: (this.$mq === "sm" ? 0 : 0.5),
            width: (this.$mq === "sm" ? "100%" : 600)
          }
        )
      },
      hoursRange() {
        const today = new Date()

        let lowEnd = 0
        let highEnd = 23

        if (!!this.localSportEvent.start_date) {
          const eventDateObj = new Date(this.localSportEvent.start_date)

          if (today.toDateString() == eventDateObj.toDateString()) {
            lowEnd = today.getHours() + 1

            if (lowEnd > 23) {
              lowEnd = 23
            }
          }
        } else {
          highEnd = 0
        }

        let arr = []

        if (highEnd == 0) {
          return arr
        }

        for (let i = lowEnd; i <= highEnd; i++) {
          arr.push({ name: ("0" + i).slice(-2), id: i })
        }

        return arr
      },
      changePlace(place) {
        this.selectedPlaceId = place.id
      },
      placeSelectedCss(place) {
        if (this.selectedPlaceId == place.id)
          return "active"

        return ""
      },
      openNewPlaceModal() {
        this.$modal.show(SportEventNewPlace,
          {
            countryCoords: this.localSportEvent.country_coords
          },
          {
            height: "auto",
            pivotX: (this.$mq === "sm" ? 0 : 0.5),
            width: (this.$mq === "sm" ? "100%" : 600)
          }
        )
      },
      changeSportEvent() {
        this.errors = []

        axios
          .put(
            `/api/v1/sport_events/${this.localSportEvent.id}`,
            { sport_event: {
              hour: this.localSportEvent.start_at_hour,
              minute: this.localSportEvent.start_at_minutes,
              id: this.localSportEvent.id
            }
          })
          .then(response => {
            showAlert(I18n.t("sport_events.show.notifications.success_save"))
            this.localSportEvent = response.data.sport_event
          })
          .catch(error => {
            this.errors = error.response.data
          })
      },
      changeSportEventPlace() {
        this.placeErrors = []

        if (this.selectedPlaceId == this.sport_event.selected_place_id)
          return

        axios
          .put(
            `/api/v1/sport_events/${this.localSportEvent.id}`,
            {
              sport_event: {
                sport_event_place_id: this.selectedPlaceId,
                id: this.localSportEvent.id
              }
            }
          )
          .then(response => {
            showAlert(I18n.t("sport_events.show.notifications.success_save"))
            this.localSportEvent = response.data.sport_event
          })
          .catch(error => {
            this.placeErrors = error.response.data
          })
      },
      bookCourt() {
        if (this.disableBook)
          return

        axios
            .put(`/api/v1/sport_events/bookings/${this.localSportEvent.id}`)
            .then(response => {
              showAlert(I18n.t("sport_events.show.notifications.success_save"))
              this.localSportEvent = response.data["sport_event"]
            })
            .catch(error => {
              console.log(error)
            })
            .then(() => {
            })
      },
    }
  }
</script>

<style scoped>
  .edit-event {
    padding: 0px 15px;
    padding-top: 65px;

    @media only screen and (max-width: 600px) {
      padding-bottom: 24px;
    }
  }

  .edit-event-block {
    border: 1px solid #d6d6d6;
    border-radius: 10px;
    padding: 18px;
    margin-top: 24px;
  }

  .edit-event .title-wrap {
    font-weight: 600;
    color: #909499;
  }

  .errors-wrap {
    color: red;
    margin: 5px 65px;
  }

  .title-description, .errors-wrap {
    font-weight: 350;
    font-size: 13px;
    margin-top: 12px;
  }

  .select-block {
    display: flex;
    margin-top: 24px;

    @media only screen and (max-width: 600px) {
      width: 90%;
      flex-direction: column;
    }
  }

  label, .switch-label {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;

    @media only screen and (max-width: 600px) {
      font-size: 12px;
    }
  }

  .select-wrap {
    min-width: 250px;
    margin-right: 24px;
  }

  .cta-btn {
    display: inline-block;
    padding: 4px 15px;
    line-height: 30px;
    text-decoration: none;
  }

  .cta-btn.purple {
    background-color: #6460ac;
    color: #fff;
  }

  .cta-btn.purple:hover {
    background-color: #5b459c;
  }

  .submit-button-wrap {
    margin-top: 24px;
  }

  .place-selector a {
    background: white;
    border: 1px solid #d8d8d8;
    font-size: 13px;
    font-weight: 450;
    color: black;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
      font-size: 12px;
    }
  }

  .place-selector a:hover {
    color: black;
  }

  .place__more-info {
    margin-top: 6px;
  }

  /* switches */

  /* The switch - the box around the slider */

  .switch-wrap {
    margin-top: 24px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 47px;
    height: 25px;
  }

  /* Hide default HTML checkbox */
  .switch input {display:none;}

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input.default:checked + .slider {
    background-color: #6460ac;
  }
  input.primary:checked + .slider {
    background-color: #2196F3;
  }
  input.success:checked + .slider {
    background-color: #8bc34a;
  }
  input.info:checked + .slider {
    background-color: #3de0f5;
  }
  input.warning:checked + .slider {
    background-color: #FFC107;
  }
  input.danger:checked + .slider {
    background-color: #f44336;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(23px);
    -ms-transform: translateX(23px);
    transform: translateX(23px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  /* Places selector */
  ul.places {
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
    list-style-type: none;
  }

  ul.places::-webkit-scrollbar {
    width: 0;
  }

  ul.places li:not(:first-child) {
    margin-left: .5rem;
  }

  ul.places li a.active {
    border: 2px solid #6460ac;
    border-radius: 5px;
  }

  ul.places li {
    position: relative;
    height: 8rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  ul.places li .place__link {
    width: 22rem;
    height: 7rem;
    background: #fff;
    border: 1px solid #e0e0e0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #333;
    text-decoration: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 1.1rem;
    font-weight: 700;

    @media only screen and (max-width: 600px) {
      width: 18rem;
    }
  }

  ul.places li .place__title {
    flex-basis: 40px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }

  ul.places li .place__address {
    margin-top: -1rem;
    font-size: 13px;
    color: #909499;
    font-weight: 400;

    @media only screen and (max-width: 600px) {
      font-size: 13px;
    }
  }

  ul.places li .place__distance {
    font-size: .7rem;
    color: #333;
    margin-top: 12px;
    padding: 5px;
    background-color: #f18d5b;
    border-radius: 5px;
  }
</style>
