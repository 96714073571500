<template>
  <a :href="this.notification.data.sport_event_url" class="notification-item-wrap notification-item-wrap_lock">
    <div v-if="!!this.notification.sport_event_name" class="event-name">
      <a :href="this.notification.data.sport_event_url">
        {{ this.notification.sport_event_name }}
      </a>
    </div>

    <div class="notification-item">
      <div class="notification-item-message">
        <span class="notification-item-img">
          <img :src="this.notification.data.profile_image" loading="lazy" v-if="!!this.notification.data.profile_image">
          <span v-else class="nickname">@{{ this.notification.data.profile_nickname }}</span>
        </span>
        <span class="notification-item-img-msg">
          {{ I18n.t("sport_events.notifications.sport_event_booked") }}
        </span>
      </div>
    </div>
  </a>
</template>

<script>
  export default {
    props: {
      notification: Object,
    },
    data() {
      return {
      }
    },
    methods: {
    }
  }
</script>

<style scoped>
</style>
