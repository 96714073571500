<template>
  <div class="current-user-sport-competitions">
    <div class="requests-for-user" style="margin-top: 12px; padding-bottom: 24px; border-bottom: 1px solid #dcdcdc;" v-if="requests_for_user.length > 0">
      <div class="page-title" style="margin-bottom: 12px;">
        {{ I18n.t('sport_competitions.index.titles.new_requests') }}
      </div>
      <div class="user-partner-item" v-for="request in requests_for_user">
        <a :href="`/users/${request.initiator.id}`">
          <img :src="imgPath(request.initiator)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
          <div style="font-size: 12px; font-weight: 500; margin-top: 6px;">@{{ request.initiator.nickname }}</div>
        </a>

        <div style="margin-right: 36px;">
          <div class="sport-alert-item-wrap">
            <div :class="`sport-alert-item mini-${request.sport_code}`" style="width: 25px; height: 25px;"></div>
          </div>

          <div style="font-size: 12px; color: grey; margin-top: 6px;">
            {{ I18n.t('sport_competitions.index.till_wins', { wins_count: request.count_of_wins }) }}
          </div>
        </div>

        <div style="display: flex; align-items: center;" class="competition-started" v-if="successFullRequests[request.id]">
          <font-awesome-icon icon="check" />
          <div style="font-size: 13px;">
            {{ I18n.t('sport_competitions.index.competition_started') }}
          </div>
        </div>

        <div style="display: flex; align-items: center;" class="competition-declined" v-if="declinedRequests[request.id]">
          <font-awesome-icon icon="check" />
          <div style="font-size: 13px;">
            {{ I18n.t('sport_competitions.index.competition_declined') }}
          </div>
        </div>

        <div style="display: flex; align-items: center;" v-if="!successFullRequests[request.id] && !declinedRequests[request.id]">
          <div>
            <button v-on:click.prevent.stop="acceptCompetition(request)" class="btn btn-success btn-sm">
              {{ I18n.t("sport_events.show.participation_accept_button") }}
            </button>
          </div>

          <div style="margin-left: 12px;">
            <a href="" v-on:click.prevent.stop="openDeclineCompetitionModal(request)" class="text-danger" style="margin-right: 0px;">
              {{ I18n.t("sport_events.show.participation_reject_from_creator_button") }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="requests-by-user" style="margin-top: 12px; padding-bottom: 24px; border-bottom: 1px solid #e7e7e7;" v-if="requests_by_user.length > 0">
      <div class="page-title page-title-requests" style="margin-bottom: 12px;">
        {{ I18n.t('sport_competitions.index.titles.your_requests') }}

        <div style="font-size: 13px; color: grey; margin-left: 12px; font-weight: 400;">
          <font-awesome-icon icon="clock" size="1x" />
          {{ I18n.t('sport_competitions.index.waiting_for_response') }}
        </div>
      </div>
      <div class="user-partners-wrap" style="display: flex">
        <div class="user-partner-item" style="align-items: flex-start;" v-for="request in requests_by_user">
          <a :href="`/users/${request.requested_user.id}`">
            <img :src="imgPath(request.requested_user)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
            <div style="font-size: 12px; font-weight: 500; margin-top: 6px;">@{{ request.requested_user.nickname }}</div>
          </a>

          <div>
            <div class="sport-alert-item-wrap">
              <div :class="`sport-alert-item mini-${request.sport_code}`" style="width: 25px; height: 25px;"></div>
            </div>

            <div style="font-size: 12px; color: grey; margin-top: 6px;">
              {{ I18n.t('sport_competitions.index.till_wins', { wins_count: request.count_of_wins }) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="competitions" style="margin-top: 12px;" v-if="inProgressCompetitions.length > 0">
      <div class="page-title" style="margin-bottom: 12px;">
        {{ I18n.t('sport_competitions.index.titles.actual_competitions') }}
      </div>

      <div class="in-progress-competitions">
        <current-user-in-progress-competition :competition="competition" :currentUserId="current_user.id" v-for="competition in inProgressCompetitions" />
      </div>
    </div>

    <div class="latest-finished-competition" style="margin-top: 12px;" v-if="latestFinishedCompetition.length > 0">
      <current-user-finished-competition :competition="latestFinishedCompetition" :currentUserId="current_user.id" :year_competitions_results="year_competitions_results" />
    </div>

    <div style="margin-top: 24px;" v-if="requests_by_user.length == 0 && inProgressCompetitions.length == 0 && !latestFinishedCompetition">
      <div style="font-size: 14px;">
        <div>
          <svg fill="#000000" height="80px" width="80px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 487.663 487.663" xml:space="preserve">
          <g id="XMLID_112_">
            <path id="XMLID_113_" d="M485.403,406.853l-39.131-85.618c0,0,16.452-71.837,16.468-72.9c0.096-6.141-2.239-12.063-6.514-16.467
              l-77.321-79.86c2.263,3.594,4.055,7.553,5.003,11.949c2.208,10.245-0.031,20.385-5.394,28.48l11.528,47.997
              c3.276,13.66-4.389,27.799-17.716,32.423c-11.179,3.835-3.927,1.346-46.351,15.906c-7.658,4.154-12.638,11.989-13.027,20.726
              L308.178,416.1c-0.617,13.75,10.035,25.391,23.784,26.007c0.372,0.016,0.754,0.023,1.128,0.023
              c13.253,0,24.278-10.44,24.877-23.808l4.123-92.205l36.25-18.722l-2.539,10.537c-1.307,5.418-0.754,11.121,1.565,16.191
              l42.708,93.447c5.703,12.476,20.442,18.048,33.022,12.305C485.614,434.158,491.123,419.369,485.403,406.853z"/>
            <path id="XMLID_114_" d="M332.148,213.35l-0.113,11.858l-77.134,26.469c-10.845,3.715-16.621,15.527-12.898,26.38
              c2.954,8.613,11.008,14.032,19.64,14.032c2.232,0,4.511-0.365,6.739-1.136l91.022-31.228c8.323-2.856,13.945-10.644,14.026-19.444
              l0.398-42.117c-4.924,4.956-11.244,8.622-18.577,10.206L332.148,213.35z"/>
            <path id="XMLID_115_" d="M212.299,117.161c16.977,9.694,38.124,4.834,49.546-10.423c1.331,7.155,4.671,14.017,10.116,19.646
              c14.73,15.193,39.008,15.583,54.21,0.852c15.217-14.731,15.597-38.984,0.876-54.203c-14.722-15.208-38.993-15.607-54.203-0.876
              c-1.524,1.484-2.799,3.123-4.032,4.777c-1.986-10.716-8.338-20.546-18.535-26.371c-18.396-10.488-41.808-4.08-52.288,14.31
              C187.51,83.271,193.901,106.673,212.299,117.161z"/>
            <path id="XMLID_116_" d="M260.011,312.068c-0.04-0.023-66.694-41.474-66.735-41.499l23.532-41.272l9.702,14.302
              c1.42,2.091,3.333,3.698,5.354,5.126c4.331-5.72,10.35-10.262,17.651-12.768l37.533-12.882l-1.598,0.35
              c-3.854,0.827-7.69,0.34-11.032-1.16l-42.66-19.176l-33.2-14.927l79.51,17.895c3.027,0.673,6.077,0.656,8.94,0.032l64.748-13.96
              c11.211-2.409,18.332-13.456,15.915-24.675c-2.409-11.21-13.409-18.349-24.675-15.916l-60.272,12.995l-75.049-16.896l24.133,0.496
              c-1.477-1.242-3.033-2.41-4.77-3.399l-12.248-6.992c-7.603-4.332-16.621-5.476-25.066-3.164
              c-8.444,2.312-15.624,7.884-19.964,15.485l-99.302,182.65L6.804,400.105c-9.449,10.001-9.004,25.771,1.007,35.221
              c9.993,9.458,25.778,9.003,35.22-1.007l66.24-70.126c1.72-1.808,3.148-3.877,4.251-6.115l29.518-60.083
              c0.065,0.04,0.115,0.097,0.18,0.138l78.941,49.084l1.136,70.401c0.226,13.62,11.34,24.513,24.911,24.513c0.138,0,0.276,0,0.414,0
              c13.756-0.226,24.733-11.56,24.513-25.325l-1.364-83.979C271.635,324.358,267.206,316.547,260.011,312.068z"/>
          </g>
          </svg>
        </div>

        <div>
          {{ I18n.t('sport_competitions.index.no_competitions_yet') }}
        </div>
      </div>

      <div style="display: flex; align-items: center; margin-top: 24px;">
        <div class="request-competition-link-wrap">
          <a href="/users?competition_invite=1" class="btn btn-sm cta-btn edit-button" style="position: relative;">
            {{ I18n.t("users.profile.sport_competitions.request_competition_full") }}

            <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-left: 5px;">
              <path d="M1 1L6 5.73684L1 10" stroke="black" stroke-width="2"/>
            </svg>
          </a>
        </div>

        <div v-if="latestFinishedCompetition">
          <a href="/sport_competitions" class="light-link">
            {{ I18n.t("users.profile.sport_competitions.show_all_finished") }}
          </a>
        </div>
      </div>
    </div>

    <div style="display: flex; align-items: center; margin-top: 24px;" v-else>
      <div class="request-competition-link-wrap">
        <a href="/users?competition_invite=1" class="btn btn-sm cta-btn edit-button" style="position: relative;">
          {{ I18n.t("users.profile.sport_competitions.request_competition_full") }}

          <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-left: 5px;">
            <path d="M1 1L6 5.73684L1 10" stroke="black" stroke-width="2"/>
          </svg>
        </a>
      </div>

      <div v-if="latestFinishedCompetition">
        <a href="/sport_competitions" class="light-link">
          {{ I18n.t("users.profile.sport_competitions.show_all_finished") }}
        </a>
      </div>
    </div>

    <modals-container />
  </div>
</template>

<script>
  import axios from "axios"
  import showAlert from "../../../utils/alert"
  import showAvatar from "../../../utils/avatar"

  import DeclineSportCompetition from "../../../components/sport_competitions/modals/decline_competition.vue"

  import AddResultModal from "../../../components/users/competitions/add_result_modal.vue"

  export default {
    props: {
      current_user: Object,
      requests_for_user: Array,
      requests_by_user: Array,
      in_progress_competitions: Array,
      latest_finished_competition: Object,
      year_competitions_results: Object
    },
    data() {
      return {
        successFullRequests: {},
        declinedRequests: {},
        inProgressCompetitions: this.in_progress_competitions,
        latestFinishedCompetition: this.latest_finished_competition
      }
    },
    created() {
      this.$eventHub.$on("change-sport-competitions", this.resetSportCompetitions)
      this.$eventHub.$on("change-declined-sport-competitions", this.resetDeclinedRequests)
    },
    methods: {
      imgPath(userObj) {
        return showAvatar(userObj, 150)
      },
      resetSportCompetitions(data) {
        console.log('response data', data)

        this.inProgressCompetitions = data.sport_competitions.in_progress_sport_competitions
        this.latestFinishedCompetition = data.latest_finished_sport_competiton
      },
      resetDeclinedRequests(competitionId) {
        this.$set(this.declinedRequests, competitionId,  1)
      },
      openDeclineCompetitionModal(competition) {
        this.$modal.show(DeclineSportCompetition,
          {
            competitionId: competition.id
          },
          {
            pivotX: (this.$mq === "sm" ? 0 : 0.5),
            width: (this.$mq === "sm" ? "100%" : 600)
          }
        )
      },
      acceptCompetition(competition) {
        axios
          .post(`/api/v1/sport_competitions/accepts`,
            {
              id: competition.id
            }
          )
          .then(response => {
            showAlert(I18n.t("users.edit_form.success_notification"))

            this.inProgressCompetitions = response.data.in_progress_sport_competitions
            this.$set(this.successFullRequests, competition.id,  1)
          })
          .catch(error => {
            this.errors = error.response.data[0]
            console.log(error)
          })
      }
    }
  }
</script>

<style scoped>
  .current-user-sport-competitions {
  }

  .page-title-requests {
    display: flex;
    align-items: baseline;

    @media only screen and (max-width: 600px) {
      justify-content: space-between;
    }
  }

  .user-partner-item {
    margin-right: 24px;
    display: flex;
    align-items: center;
    border: 1px solid #e7e7e7;
    padding: 10px 12px;
    border-radius: 10px;
    margin-right: 12px;
    width: fit-content;
  }

  .user-partner-item a {
    text-decoration: none;
    margin-right: 36px;
    font-size: 13px;
  }

  .user-partner-avatar-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  .sport-alert-item-wrap {
    background-color: #7d79b9;
    height: 40px;
    width: 45px;
    border-radius: 10px;
    position: relative;
    margin-right: 12px;
  }

  .sport-alert-item {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .in-progress-competitions .in-progress-competition {
    padding-bottom: 24px;
    border-bottom: 1px solid #e7e7e7;
    margin-top: 24px;
  }

  .in-progress-competitions .in-progress-competition:first-child {
    margin-top: 0px;
  }

  .latest-finished-competition {
    padding-bottom: 24px;
    border-bottom: 1px solid #e7e7e7;
  }

  .competition-started svg {
    color: #0b6135;
    margin-right: 3px;
  }

  .competition-declined svg {
    color: #dc3545;
    margin-right: 3px;
  }

  .request-competition-link-wrap {
    margin-right: 12px;
  }

  .request-competition-link-wrap a {
    background: white;
    border: 1px solid #d8d8d8;
    font-size: 13px;
    font-weight: 450;
  }

  .mini-badminton {
    background-image: url("../../../images/mini-image-badminton.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-squash {
    background-image: url("../../../images/mini-image-squash.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-football {
    background: url("../../../images/mini-image-football.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-basketball {
    background: url("../../../images/mini-image-basketball.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-tennis {
    background: url("../../../images/mini-image-tennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-volleyball {
    background: url("../../../images/mini-image-volleyball.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-tabletennis {
    background: url("../../../images/mini-image-tabletennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
    left: 12px !important;
  }
</style>
