<template>
  <div class="place-inputs" id="place-wrap" data-slideout-ignore>
    <div class="page-title" style="font-size: 18px; padding: 0px;">{{ I18n.t("users.edit_form.modals.place_title") }}</div>

    <div class="user-place-wrap" v-if="!this.responseCompleted">
      <div class="errors" v-if="this.errors">
        {{ this.errors }}
      </div>

      <div class="address-form" style="font-size: 16px;" v-if="this.currentAddressView == 'form'">
        <div class="field-wrap form-group vue-form-generator col-xs-12" style="width: 100%;">
          <label>{{ I18n.t("users.edit_form.labels.address") }}</label>
          <input required ref="autocomplete" :placeholder="I18n.t('sport_events.new_form.address')" class="form-control" onfocus="value = ''" v-model="newPlace.street_name" required="true" type="text" autocomplete="new-password">
        </div>

        <div>
          <a href="" v-on:click.prevent.stop="openMap()" class="light-link">
            {{ I18n.t("users.edit_form.modals.open_map") }}
          </a>
        </div>

        <div class="submit-button-wrap" style="width: 100%;">
          <a href="" class="btn cta-btn purple btn-sm submit-link" v-on:click.prevent.stop="sendResponse()">
            {{ I18n.t("common.buttons.save") }}
          </a>
        </div>
      </div>

      <div id="map-wrap">
        <div id="address-map">
        </div>
      </div>

      <div style="position: absolute; left: 50%; top: 50%; color: #2381cc; display: none;" id="map-pin">
        <font-awesome-icon icon="map-pin"  size="lg" />
      </div>

      <div v-if="this.currentAddressView == 'map'" style="margin-top: 12px;">
        <div>lat: {{ this.mapCoordsLat }}</div>
        <div>lng: {{ this.mapCoordsLng }}</div>

        <div style="margin-top: 12px;">
          <a href="" v-on:click.prevent.stop="closeMap()" class="light-link">
            {{ I18n.t("users.edit_form.modals.close_map") }}
          </a>
        </div>

        <div class="submit-button-wrap" style="width: 100%;">
          <a href="" class="btn cta-btn purple btn-sm" v-on:click.prevent.stop="sendResponse()" style="width: 100%;">
            {{ I18n.t("common.buttons.save") }}
          </a>
        </div>
      </div>
    </div>

    <div class="user-place-success-info" v-else>
      <div class="user-place-success-info__image">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
        <ellipse style="fill:#32BEA6;" cx="256" cy="256" rx="256" ry="255.832"/>
        <polygon style="fill:#FFFFFF;" points="235.472,392.08 114.432,297.784 148.848,253.616 223.176,311.52 345.848,134.504
          391.88,166.392 "/>
        </svg>
      </div>

      <div class="user-place-success-info__message">
        {{ I18n.t('users.edit_form.user_places.success_message') }}
      </div>

      <div class="user-place-success-info__button-wrap" style="width: 100%;">
        <a href="" class="btn cta-btn cancel" v-on:click.prevent.stop="$emit('close')" style="width: 100%;">
          {{ I18n.t("common.buttons.close") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  import { validators } from "vue-form-generator"

  export default {
    props: {
      current_user: Object,
      default_map_coords: Object
    },
    data: function() {
      return {
        errors: null,
        responseCompleted: false,
        currentAddressView: 'form',
        currentGeoFromGoogle: null,
        newPlace: {
          city_id: this.current_user.current_city_id,
          street_name: this.current_user.street_name
        },
        mapCoordsLat: this.default_map_coords.latitude,
        mapCoordsLng: this.default_map_coords.longitude,
        navigatorRequested: false
      }
    },
    mounted() {
      if (!this.$refs.autocomplete) {
        return
      }

      this.autocomplete = new google.maps.places.Autocomplete(
        (this.$refs.autocomplete),
        { types: ["address"], language: "en" }
      );

      this.autocomplete.addListener("place_changed", () => {
        try {
          let place = this.autocomplete.getPlace();

          console.log(place)

          let ac = place.address_components;
          let lat = place.geometry.location.lat();
          let lon = place.geometry.location.lng();

          console.log(lat, lon)

          let cityName = _.find(place.address_components, function(o) {
            return _.isEqual(o.types, ["locality", "political"]) ||
                     _.isEqual(o.types, ["administrative_area_level_3", "political"]) ||
                         _.isEqual(o.types, ["postal_town"])
          });

          this.newPlace.latitude = lat;
          this.newPlace.longitude = lon;
          this.newPlace.google_place_id = place.place_id;
          this.newPlace.street_name = place.name;

          if(cityName) {
            this.newPlace.city_name = cityName["long_name"];
          }
        } catch(e) {
          I18n.t("sport_events.errors.address_error")
        }
      });
    },
    methods: {
      openMap(zoom = 5) {
        this.currentAddressView = 'map'

        if (!this.navigatorRequested) {
          this.openNavigator()
        }

        const map = new google.maps.Map(document.getElementById("address-map"), {
          zoom: zoom,
          center: { lat: this.mapCoordsLat, lng: this.mapCoordsLng },
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          gestureHandling: "greedy"
        })

        map.addListener("center_changed", () => {
          this.mapCoordsLat = map.getCenter().lat()
          this.mapCoordsLng = map.getCenter().lng()
        })

        document.querySelector('#address-map').classList.add('address-map-opened')
        document.querySelector('#place-wrap').classList.add('address-map-wrap-opened')
        const addressMapHeight = document.querySelector('#address-map').offsetHeight
        document.querySelector('#map-pin').style.top = `${addressMapHeight / 2 + 60}px`
        document.querySelector('#map-pin').style.display = 'block'
      },
      closeMap() {
        this.currentAddressView = 'form'

        document.querySelector('#address-map').classList.remove('address-map-opened')
        document.querySelector('#place-wrap').classList.remove('address-map-wrap-opened')
        document.querySelector('#map-pin').style.display = 'none'
      },
      openNavigator() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            console.log("Latitude: " + position.coords.latitude + "<br>Longitude: " + position.coords.longitude);
            this.mapCoordsLat = position.coords.latitude
            this.mapCoordsLng = position.coords.longitude

            this.navigatorRequested = true
            this.openMap(15)
          }, err => {
            console.log(err)
            if(err.PERMISSION_DENIED){
              console.log("The User have denied the request for Geolocation.")
            }
          })
        } else {
          console.log("The Browser Does not Support Geolocation");
        }
      },
      sendResponse() {
        this.errors = null

        if (this.currentAddressView == 'form' && !this.newPlace.google_place_id) {
          this.errors = I18n.t("sport_events.errors.address_not_selected")
          return
        }

        let requestParams = this.newPlace

        if (this.currentAddressView != 'form') {
          requestParams = {
            latitude: this.mapCoordsLat,
            longitude: this.mapCoordsLng
          }
        }

        axios
            .post("/api/v1/user_places/",
              {
                user_place: {
                  ...requestParams
                }
              }
            )
            .then(response => {
              this.responseCompleted = true
              this.$eventHub.$emit("new-user-place", response.data)
              this.$emit("close")
            })
            .catch(error => {
              this.errors = I18n.t("sport_events.errors.address_error")
            })
      }
    }
  }
</script>

<style scoped>
  .place-inputs {
    height: 100%;
    height: 80%;
    margin: 20px auto;

    width: 80%;

    @media only screen and (max-width: 600px) {
      width: 300px;
    }
  }

  .address-map-opened {
    width: 100%;
    min-height: 400px;
  }

  .address-map-wrap-opened {
    @media only screen and (max-width: 600px) {
      width: 90%;
    }
  }

  .title-description, .errors-wrap {
    font-weight: 300;
    font-size: 12px;
    text-align: center;
    margin: 15px 60px;
    margin-top: 5px;
    display: flex;
    color: grey;

    @media only screen and (max-width: 600px) {
      margin: 15px 0px;
    }
  }

  .user-place-wrap {
  }

  .submit-link {
    width: 100%;

    @media only screen and (min-width: 600px) {
      width: 30%;
    }
  }

  .title {
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
  }

  .cta-btn.green {
    background-color: #0c6035;
    color: #fff;
  }

  .cta-btn {
    display: inline-block;
    padding: 4px 15px;
    line-height: 30px;
    text-decoration: none;
  }

  .errors {
    color: #e65328;
    text-align: center;
    padding-bottom: 20px;
  }

  .user-place-success-info {
    text-align: center;
  }

  .user-place-success-info__image {
    margin: 20px;
  }

  .user-place-success-info svg {
    width: 80px;
  }

  .cta-btn.cancel {
    color: grey;
    border: 1px solid grey;
  }

  .user-place-success-info__button-wrap {
    margin-top: 40px;
  }

  .submit-button-wrap {
    margin-top: 24px;
  }
</style>
