<template>
  <div v-if="$mq != 'sm'">
    <div class="container footer-container">
      <div class="footer">
        <ul>
          <li>
            <select @change="changeLocale" v-model="currentLocale">
              <option value="ru">{{ I18n.t("common.locales.ru") }}</option>
              <option value="en">{{ I18n.t("common.locales.en") }}</option>
            </select>
          </li>
          <li>© {{ new Date().getFullYear() }} <span class="name">Spotty</span></li>
          <li>
            <a href="/pages/about">{{ I18n.t("side_menu.names.about") }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div v-else-if="$mq == 'sm' && this.user_guest">
    <nav class="navbar fixed-bottom navbar-light bg-light save-button-nav mob-registration-footer">
      <div class="registration-wrap">
        <div class="registration-link-wrap">
          <a href="/users/sign_up" class="registration-link sign-up-link">
            {{ I18n.t("side_menu.names.sign_up") }}
          </a>
        </div>

        <div class="registration-link-wrap">
          <a href="/users/sign_in" class="registration-link sign-in-link">
            {{ I18n.t("side_menu.names.sign_in") }}
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
  import axios from "axios"

  export default {
    props: {
      locale: String,
      user_guest: Boolean
    },
    data() {
      return {
        currentLocale: this.locale
      }
    },
    methods: {
      changeLocale() {
        axios
          .post(
            "/api/v1/users/switch",
            { user: { locale: this.currentLocale } }
          )
          .then(response => {
            window.location.reload();
          })
          .catch(error => {
            this.errors = true
          })
      }
    }
  }
</script>

<style scoped>
  .footer-container {
    padding-right: 16px!important;
  }

  .container {
    max-width: 750px;
  }

  .footer {
    border-top: 1px solid #e1e4e8;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 80px;
  }

  .footer ul {
    display: flex;
    list-style: none;
  }

  .footer ul li {
    margin-right: 16px;
    color: #586069;
    font-size: 12px;
  }

  .registration-wrap {
    display: flex;
    justify-content: space-around;
    margin: 10px;
  }

  .registration-link-wrap {
    height: 100%;
    margin-right: 25px;
  }

  .registration-link {
    width: 100%;
    background: #6460ac;
    color: #fff;
    font-size: .9em;
    text-align: center;
    padding: 12px 18px;
    border-radius: 14px;
  }

  .sign-in-link {
    background: #81a65a;
  }
</style>
