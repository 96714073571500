<template>
  <div class="user-play-status-modal-wrap">
    <div class="page-title" style="font-size: 18px; padding: 0px;">{{ I18n.t("users.play_status.modal_title") }}</div>

    <div>
      <div class="user-play-status-modal">
        <div style="margin-bottom: 12px;">
          <font-awesome-icon icon="circle" size="xs" class="play_status_ready" />
          {{ I18n.t("users.play_status.ready") }} - {{ I18n.t("users.play_status.ready_description") }}
        </div>
        <div style="margin-bottom: 12px;">
          <font-awesome-icon icon="circle" size="xs" class="play_status_not_ready" />
          {{ I18n.t("users.play_status.not_ready") }} - {{ I18n.t("users.play_status.not_ready_description") }}
        </div>
        <div style="line-height: 1.2;">
          <font-awesome-icon icon="circle" size="xs" class="play_status_not_confirmed" />
          {{ I18n.t("users.play_status.not_confirmed") }} - {{ I18n.t("users.play_status.not_confirmed_description") }}
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  props: {
    user: Object
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  unmounted() {
  },
  methods: {
  }
}
</script>

<style scoped>
  .user-play-status-modal-wrap {
    line-height: 1.2;
    padding: 30px;
  }

  .play_status_ready {
    color: #009900;
  }

  .play_status_not_ready {
    color: #cc0100;
  }

  .play_status_not_confirmed {
    color: #fecb00;
  }
</style>
