import { render, staticRenderFns } from "./play_status_description.vue?vue&type=template&id=02ecaf28&scoped=true&"
import script from "./play_status_description.vue?vue&type=script&lang=js&"
export * from "./play_status_description.vue?vue&type=script&lang=js&"
import style0 from "./play_status_description.vue?vue&type=style&index=0&id=02ecaf28&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02ecaf28",
  null
  
)

export default component.exports