<template>
  <div class="add-result-wrap">
    <div class="title" style="text-align: left;">
      {{ I18n.t("sport_competitions.results.modal_title") }}
    </div>
    <div style="font-size: 13px; font-style: italic; margin-top: 6px;">
      {{ I18n.t(`sports.${this.sportCode}`) }}
      {{ I18n.t('sport_competitions.index.till_wins', { wins_count: this.countOfWins }) }}
    </div>
    <sport-competition-result-submission :currentUserId="userId" :result="result" :currentUserHomePage="true" />

    <div style="margin-top: 36px" v-if="showCloseButton">
      <a href="" class="btn cta-btn btn-sm cancel" v-on:click.prevent.stop="$emit('close')" >
        {{ I18n.t("common.buttons.close") }}
      </a>
    </div>
  </div>
</template>

<script>
  import axios from "axios"

  export default {
    props: {
      userId: Number,
      countOfWins: Number,
      sportCode: String,
      result: Object
    },
    data: function() {
      return {
        showCloseButton: false
      }
    },
    created() {
      this.$eventHub.$on("result-submitted", this.resetSportCompetitions)
    },
    computed: {
    },
    methods: {
      resetSportCompetitions(res) {
        this.showCloseButton = true

        this.$emit("close")
      }
    }
  }
</script>

<style scoped>
  .add-result-wrap {
    height: 80%;
    width: 300px;
    margin: 0px auto;
    margin-top: 20px;
  }

  .completed-info-wrap {
    height: 75%;
    padding-top: 20px;
  }

  .completed-info {
    height: 80%;
    text-align: center;
  }

  .completed-info-img img {
    width: 120px;
    height: 120px;
  }

  .title {
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 450;
    padding-top: 15px;
    font-weight: bold;
  }

  .submit-button-wrap {
    margin-top: 30px;

    @media only screen and (max-width: 600px) {
      margin-bottom: 20px;
    }
  }

  .cta-btn {
    display: inline-block;
    padding: 4px 15px;
    line-height: 30px;
    text-decoration: none;
  }

  .cta-btn.green {
    background-color: #0c6035;
    color: #fff;
  }

  .cta-btn.cancel {
    color: grey;
    border: none;
    padding: 0px;
    font-size: 15px;
  }

  .submit-button-wrap .green {
    margin-right: 10px;
  }
</style>
