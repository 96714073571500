<template>
  <div class="account-not-ready">
    <div>
      <div class="denied-icon">
        <font-awesome-icon icon="ban" size="lg" />
      </div>
      <div style="margin-top: 12px;">
        {{ I18n.t("sport_events.user_not_allowed_to_create.title") }}
      </div>
      <div style="margin-top: 12px;">
        {{ I18n.t("sport_events.user_not_allowed_to_create.request_title") }}
      </div>
      <ul style="margin-top: 6px;">
        <li>
          {{ I18n.t("sport_events.user_not_allowed_to_create.first_item") }}
        </li>
        <li>
          {{ I18n.t("sport_events.user_not_allowed_to_create.second_item") }}
        </li>
        <li>
          {{ I18n.t("sport_events.user_not_allowed_to_create.third_item") }}
        </li>
      </ul>

      <div>
        <a href="/users/edit_profile" style="font-size: 15px; color: blue;">
          {{ I18n.t("sport_events.user_not_allowed_to_create.edit_profile_link") }}
        </a>
      </div>

      <div style="margin-top: 12px;">
        <a href="/sport_events/new?empty_profile=1" style="font-size: 15px; color: grey; font-weight: 400;">
          {{ I18n.t("sport_events.user_not_allowed_to_create.go_to_new_forcely") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
    },
  }
</script>

<style scoped>
  .account-not-ready {
    padding: 0px 15px;
    padding-top: 75px;
    line-height: 1.2;

    @media only screen and (max-width: 600px) {
      padding-bottom: 24px;
    }
  }

  .account-not-ready ul li::marker {
    color: #0E6FC9;
  }

  .account-not-ready ul li {
    font-weight: 450;
  }

  .denied-icon {
    font-size: 80px;
    color: #711111;
    text-align: center;
  }
</style>
