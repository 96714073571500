<template>
  <div class="finished-competition"  style="">
    <div class="page-title" style="margin-bottom: 12px; display: flex; align-items: center;">
      <div>
        {{ I18n.t('sport_competitions.index.titles.finished_competition') }}
      </div>

      <div class="win-lose">
        <div class="win-notification" v-if="this.isCurrentUserWinner()">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-party-popper"><path d="M5.8 11.3 2 22l10.7-3.79"/><path d="M4 3h.01"/><path d="M22 8h.01"/><path d="M15 2h.01"/><path d="M22 20h.01"/><path d="m22 2-2.24.75a2.9 2.9 0 0 0-1.96 3.12c.1.86-.57 1.63-1.45 1.63h-.38c-.86 0-1.6.6-1.76 1.44L14 10"/><path d="m22 13-.82-.33c-.86-.34-1.82.2-1.98 1.11c-.11.7-.72 1.22-1.43 1.22H17"/><path d="m11 2 .33.82c.34.86-.2 1.82-1.11 1.98C9.52 4.9 9 5.52 9 6.23V7"/><path d="M11 13c1.93 1.93 2.83 4.17 2 5-.83.83-3.07-.07-5-2-1.93-1.93-2.83-4.17-2-5 .83-.83 3.07.07 5 2Z"/></svg>
          </div>

          <div style="margin-left: 6px;font-weight: 500;color: #004e00; font-size: 13px;">
            {{ I18n.t('users.profile.sport_competitions.you_won') }}
          </div>
        </div>

        <div class="lose-notification" v-else>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-x"><circle cx="12" cy="12" r="10"/><path d="m15 9-6 6"/><path d="m9 9 6 6"/></svg>
          </div>

          <div style="margin-left: 6px;font-weight: 500;color: #5c0000; font-size: 13px">
            {{ I18n.t('users.profile.sport_competitions.you_lose') }}
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex; align-items: center;">
      <div style="display: flex; align-items: center;">
        <a :href="`/users/${competition.requested_user.nickname}`" style="margin-right: 32px; text-align: center; font-size: 12px; text-decoration: none;">
          <img :src="imgPath(competition.requested_user)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
          <div style="margin-top: 3px;">
            @{{ competition.requested_user.nickname }}
          </div>
        </a>
        <div style="font-size: 30px">
          {{ competition.requested_user_wins }}
        </div>
      </div>
      <div style="display: flex; align-items: center; margin-right: 6px; margin-left: 6px;">:</div>
      <div style="display: flex; align-items: center; margin-right: 24px;">
        <div style="font-size: 30px">
          {{ competition.initiated_user_wins }}
        </div>
        <a :href="`/users/${competition.initiator.nickname}`" style="margin-left: 32px;  text-align: center; font-size: 12px; text-decoration: none;">
          <img :src="imgPath(competition.initiator)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
          <div style="margin-top: 3px;">
            @{{ competition.initiator.nickname }}
          </div>
        </a>
      </div>

      <div style="padding-left: 24px; border-left: 1px solid #dcdcdc;">
        <div class="sport-alert-item-wrap" style="margin-right: 24px; width: 40px;">
          <div :class="`sport-alert-item mini-${competition.sport_code}`" style="width: 20px; height: 20px;"></div>
        </div>

        <div style="font-size: 13px; color: grey; margin-top: 6px;">
          {{ I18n.t('sport_competitions.index.till_wins', { wins_count: competition.count_of_wins }) }}
        </div>
      </div>
    </div>

    <div style="margin-top: 16px; display: flex; align-items: center;">
      <div style="margin-right: 16px;">
        <a :href="`/sport_competitions/new?user_id=${this.opponentUserId()}`" style="text-transform: none; color: black; font-weight: 400; text-decoration: none;" class="light-link">
          <font-awesome-icon icon="redo" size="sm" />
          {{ I18n.t('sport_events.retry_link') }}
        </a>
      </div>

      <div>
        <a :href="`/sport_competitions/${competition.id}`" class="light-link">
          {{ I18n.t('sport_competitions.index.more_info_link') }}
        </a>
      </div>
    </div>

    <current-user-competitions-year-result :currentUserId="this.currentUserId" :result="this.year_competitions_results[this.competition.initiator.id == this.currentUserId ? this.competition.requested_user.id : this.competition.initiator.id]" v-if="this.year_competitions_results[this.competition.initiator.id == this.currentUserId ? this.competition.requested_user.id : this.competition.initiator.id]" :opponentNickname="this.competition.initiator.id == this.currentUserId ? this.competition.requested_user.nickname : this.competition.initiator.nickname" />

    <modals-container />
  </div>
</template>

<script>
  import axios from "axios"
  import showAlert from "../../../utils/alert"
  import showAvatar from "../../../utils/avatar"

  import AddResultModal from "../../../components/users/competitions/add_result_modal.vue"

  export default {
    props: {
      currentUserId: Number,
      competition: Object,
      year_competitions_results: Object
    },
    data() {
      return {
        localCompetition: this.competition
      }
    },
    created() {
    },
    methods: {
      imgPath(userObj) {
        return showAvatar(userObj, 150)
      },
      openAddResultModal(competition) {
        this.$modal.show(AddResultModal,
          {
            userId: this.currentUserId,
            result: competition.new_result,
            countOfWins: competition.count_of_wins,
            sportCode: competition.sport_code

          },
          {
            pivotX: (this.$mq === "sm" ? 0 : 0.5),
            width: (this.$mq === "sm" ? "100%" : 600)
          }
        )
      },
      opponentUserId() {
        return this.competition.initiator.id == this.currentUserId ? this.competition.requested_user.id : this.competition.initiator.id
      },
      isCurrentUserWinner() {
         return this.competition.winner_id == this.currentUserId
      }
    }
  }
</script>

<style scoped>
  .current-user-sport-competitions {
  }

  .page-title-requests {
    display: flex;
    align-items: baseline;

    @media only screen and (max-width: 600px) {
      justify-content: space-between;
    }
  }

  .user-partner-avatar-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  .sport-alert-item-wrap {
    background-color: #7d79b9;
    height: 40px;
    width: 45px;
    border-radius: 10px;
    position: relative;
    margin-right: 12px;
  }

  .sport-alert-item {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .win-lose {
    margin-left: 12px;
  }

  .win-notification, .lose-notification {
    display: flex;
    align-items: center;
  }

  .mini-badminton {
    background-image: url("../../../images/mini-image-badminton.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-squash {
    background-image: url("../../../images/mini-image-squash.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-football {
    background: url("../../../images/mini-image-football.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-basketball {
    background: url("../../../images/mini-image-basketball.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-tennis {
    background: url("../../../images/mini-image-tennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-volleyball {
    background: url("../../../images/mini-image-volleyball.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-tabletennis {
    background: url("../../../images/mini-image-tabletennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
    left: 12px !important;
  }
</style>
